import { FC, ReactNode } from 'react'
import Link from 'next/link'

type BreadcrumbType = {
  links: { text: ReactNode; url: string }[]
}

const Breadcrumb: FC<BreadcrumbType> = ({ children, links }) => {
  return (
    <div className='flex-row align-items-start fs-sm gap-1'>
      <div className='flex-fill lh-input flex-row'>
        {links.map((v, i) => {
          const isLast = i + 1 === links.length
          return isLast ? (
            <span className='body-secondary'>{v.text}</span>
          ) : (
            <>
              <Link href={v.url}>
                <a href={v.url} className='text-link cursor-pointer'>
                  {v.text}
                </a>
              </Link>
              {i + 1 !== links.length && (
                <span className='user-select-none body-secondary'>
                  &nbsp;/&nbsp;
                </span>
              )}
            </>
          )
        })}
      </div>
      {children}
    </div>
  )
}

export default Breadcrumb
