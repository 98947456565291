import { FC, ReactNode } from 'react'
import useNotLoggedInRedirect from 'common/hooks/useNotLoggedInRedirect'
import useLoggedInRedirect from 'common/hooks/useLoggedInRedirect'
import { useUser } from 'common/services/useUser'
import { Res } from 'common/types/responses'

type ProfileContainerType = {
  children: (data: Res['user']) => ReactNode
}

const ProfileContainer: FC<ProfileContainerType> = ({ children }) => {
  const { data } = useUser({})
  useNotLoggedInRedirect()
  useLoggedInRedirect(true)
  return <>{children(data)}</>
}

export default ProfileContainer
