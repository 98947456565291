import { useUser } from 'common/services/useUser'
import { useEffect, useRef } from 'react'
import { getApi } from 'common/api'

export default function (runOnce?: boolean) {
  const { data: user } = useUser({})
  const ref = useRef(runOnce ? false : user ? true : false)
  useEffect(() => {
    if (user && !ref.current) {
      getApi().loginRedirect(user, '/dashboard')
    }
    ref.current = !!user
  }, [user])
  return { user }
}
