import { MailQueueItem, Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { sortBy } from 'lodash'

export const mailQueueItemByBarcodeService = service
  .enhanceEndpoints({ addTagTypes: ['MailQueueItemByBarcode'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMailQueueItemByBarcodes: builder.query<
        Res['mailQueueItemByBarcodes'],
        Req['getMailQueueItemByBarcodes']
      >({
        providesTags: (res, _, req) => [
          { id: req.id, type: 'MailQueueItemByBarcode' },
        ],
        query: (query) => ({
          url: `operations/queueitems/barcode/${query.id}`,
        }),
        transformResponse: (res: MailQueueItem) => {
          return {
            ...res,
            sides: sortBy(res.sides || [], (side) => {
              return side?.side_number
            }).filter((v) => !!v.url),
          }
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getMailQueueItemByBarcodes(
  store: any,
  data: Req['getMailQueueItemByBarcodes'],
  options?: Parameters<
    typeof mailQueueItemByBarcodeService.endpoints.getMailQueueItemByBarcodes.initiate
  >[1],
) {
  return store.dispatch(
    mailQueueItemByBarcodeService.endpoints.getMailQueueItemByBarcodes.initiate(
      data,
      options,
    ),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useGetMailQueueItemByBarcodesQuery,
  // END OF EXPORTS
} = mailQueueItemByBarcodeService

/* Usage examples:
const { data, isLoading } = useGetMailQueueItemByBarcodesQuery({ id: 2 }, {}) //get hook
const [createMailQueueItemByBarcodes, { isLoading, data, isSuccess }] = useCreateMailQueueItemByBarcodesMutation() //create hook
mailQueueItemByBarcodeService.endpoints.getMailQueueItemByBarcodes.select({id: 2})(store.getState()) //access data from any function
*/
