import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Button } from './forms/Button'
import { FC, ReactNode, useEffect, useRef } from 'react'
import { useState } from 'preact/compat'
import Input from './forms/Input'
import { safeParseEventValue } from 'common/utils/forms/safeEventParseValue'
import { GUID } from 'common/utils/string/GUID'

interface Confirm {
  title: ReactNode
  isOpen: boolean
  isDanger?: boolean
  onYes?: (toggle: () => void) => void
  onNo?: () => void
  challenge?: string
  noText?: string
  disabled?: boolean
  disabledYes?: boolean
  yesText?: string
  toggle?: () => void
}

const Confirm: FC<Confirm> = ({
  challenge,
  children,
  disabled,
  disabledYes,
  isDanger,
  isOpen,
  noText = 'Cancel',
  onNo,
  onYes,
  title,
  toggle,
  yesText = 'OK',
}) => {
  const id = useRef(GUID())
  useEffect(() => {
    if (challenge) {
      document.getElementById(id.current)?.focus()
    }
  }, [challenge])
  const no = () => (onNo ? onNo() : toggle?.())
  const yes = () => (onYes ? onYes(toggle) : toggle?.())
  const [challengeAnswer, setChallengeAnswer] = useState('')
  const invalidateChallenge = !!challenge && challenge !== challengeAnswer
  return (
    <Modal unmountOnClose isOpen={isOpen} toggle={no}>
      <ModalHeader toggle={no}>{title}</ModalHeader>
      <ModalBody>
        {children}
        {challenge && (
          <Input
            id={id.current}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && challengeAnswer === challenge) {
                yes()
              }
            }}
            className={'mt-2'}
            value={challengeAnswer}
            onChange={(e) => setChallengeAnswer(safeParseEventValue(e))}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          style={{ width: 90 }}
          theme='secondary'
          data-test='confirm-no'
          disabled={disabled}
          onClick={no}
        >
          {noText}
        </Button>
        {isDanger ? (
          <Button
            style={{ width: 90 }}
            theme='danger'
            data-test='confirm-yes'
            disabled={disabled || disabledYes || invalidateChallenge}
            iconRight='fas fa-trash'
            onClick={yes}
          >
            {yesText}
          </Button>
        ) : (
          <Button
            style={{ width: 90 }}
            data-test='confirm-yes'
            disabled={disabled || disabledYes || invalidateChallenge}
            onClick={yes}
            shortcut='Enter'
            className='shortcut-absolute'
          >
            {yesText}
          </Button>
        )}{' '}
      </ModalFooter>
    </Modal>
  )
}

export default Confirm
