import { FC } from 'react'
import Select from './base/forms/Select'

const businessTypeDescriptions = {
  ASSOCIATION: 'Association, club or society',
  LIMITED_COMPANY: 'Limited company (LTD, LP, LLP, LLC, Corp)',
  NON_PROFIT: 'Charity / non-profit',
  SOLE_TRADER: 'Individual / sole trader',
  TRUST: 'Trust, foundation or fund',
  UNINCORPORATED: 'Unincorporated / not yet registered',
}

export enum BusinessType {
  'LIMITED_COMPANY' = 'LIMITED_COMPANY',
  'SOLE_TRADER' = 'SOLE_TRADER',
  'NON_PROFIT' = 'CHARITY',
  'UNINCORPORATED' = 'UNINCORPORATED',
  'TRUST' = 'TRUST',
  'ASSOCIATION' = 'ASSOCIATION',
}

export const BusinessTypeData = (
  [
    BusinessType.LIMITED_COMPANY,
    BusinessType.SOLE_TRADER,
    BusinessType.NON_PROFIT,
    BusinessType.TRUST,
    BusinessType.UNINCORPORATED,
    BusinessType.ASSOCIATION,
  ] as (keyof typeof businessTypeDescriptions)[]
).map((type) => ({
  label: businessTypeDescriptions[type],
  value: type as string,
}))

type BusinessTypeSelectType = {
  value: BusinessType | undefined | null
}

const BusinessTypeSelect: FC<BusinessTypeSelectType> = ({ value }) => {
  return (
    <Select
      value={BusinessTypeData.find((b) => b.value === value) ?? null}
      options={BusinessTypeData}
    />
  )
}

export default BusinessTypeSelect
