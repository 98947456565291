import {
  Company,
  CompanySummary,
  Subscription,
  SubscriptionSummary,
} from 'common/types/responses'
import { FC, useMemo } from 'react'
import CompanyStatus from './CompanyStatus'
import Tooltip from './base/Tooltip'
import Highlight from './Highlight'
import SiteSearch from './SiteSearch'
import SearchHighlight from './SearchHighlight'

type CompanyNameType = {
  company: SubscriptionSummary | Subscription | Company | CompanySummary
  condensed?: boolean
  hideNotes?: boolean
  companySuggestion?: string
}

export function getCompanyNameParts(
  company: CompanyNameType['company'],
  condensed?: CompanyNameType['condensed'],
) {
  const legal = company?.company_name || company?.name
  const trading = company?.trading_name
  const shouldAddTrading = !!trading && !!legal && trading !== legal
  const parts: ({
    label: string
    suggestion?: string
    prefix?: string | null
    postfix?: string | null
  } | null)[] = [
    { label: legal || trading },
    shouldAddTrading
      ? {
          label: trading,
          postfix: condensed ? ')' : null,
          prefix: condensed ? '(' : 'Trading as ',
        }
      : null,
    company?.previous_company_name
      ? { label: `${company.previous_company_name}`, prefix: 'Previously:' }
      : null,
  ].filter((v) => !!v?.label)
  return parts
}

export function getCompanyName(company: CompanyNameType['company']) {
  return getCompanyNameParts(company)
    .map((v) => `${v?.prefix || ''}${v?.label}${v?.postfix || ''}`)
    .join(' ')
}

const CompanyName: FC<CompanyNameType> = ({
  company,
  companySuggestion,
  condensed,
  hideNotes,
}) => {
  const parts = useMemo(() => {
    if (!company) return null
    return getCompanyNameParts(company, condensed)
  }, [company, condensed])

  if (!company) return null
  if (condensed) {
    return <span>{getCompanyName(company)}</span>
  }

  return (
    <>
      <div className='flex-row align-items-start'>
        <div>
          {parts?.map((v, i) => {
            const inner = (
              <>
                {!!v?.prefix && <span className='me-1'>{v?.prefix}</span>}
                <Highlight
                  query={companySuggestion ? `${companySuggestion}` : ''}
                >
                  <SearchHighlight>{`${v?.label || ''}`}</SearchHighlight>
                </Highlight>
                {v?.postfix}
              </>
            )
            return (
              <div
                key={i}
                className={i > 0 ? 'fs-xs flex-row body-secondary' : ''}
              >
                {i === 0 && !!company.mail_notes && !hideNotes ? (
                  <Tooltip content={company.mail_notes}>
                    {inner}
                    <span className='text-info fs-sm'>
                      {' '}
                      Notes <span className='fa fa-info-circle' />
                    </span>
                  </Tooltip>
                ) : (
                  inner
                )}
              </div>
            )
          })}
        </div>
        <div
          style={{ marginTop: 3 }}
          className='flex-row align-items-center ms-1'
        >
          <CompanyStatus status={company.status} />
        </div>
      </div>
    </>
  )
}

export default CompanyName
