import { PagedRequest } from 'common/types/requests'
import { PagedResponse } from 'common/types/responses'

export default function <T>(
  req: PagedRequest<any>,
  res: { count: number; results: any[] },
) {
  return {
    ...res,
    currentPage: req.page || 1,
    pageSize: req.page_size,
    totalPages: Math.ceil(res.count / req.page_size),
  } as PagedResponse<T>
}
