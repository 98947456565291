import dayjs from 'dayjs'

export const barcodeDateFormat = 'YYMMDD'
export default function decodeBatchCode(str: string | undefined) {
  if (!str)
    return {
      date: null,
      display: '',
      job: '',
    }
  try {
    const parts = str.split('-')
    const ds = parts[0]
    const dateString = `20${ds[4]}${ds[5]}${ds[0]}${ds[1]}${ds[2]}${ds[3]}`
    const date = dayjs(dateString, barcodeDateFormat).toISOString()
    return {
      date,
      display: `${dayjs(date).format('Do MMM YYYY')} - Job ${parseInt(
        parts[1],
      )} `,
      job: parseInt(parts[1]),
    }
  } catch (e) {
    console.error(`Invalid barcode${str}`)
    return {
      date: null,
      display: '',
      job: '',
    }
  }
}
export const formatBatchCode = (code: string) => {
  if (!code) return ''
  const parts = code.split('-')
  const ds = parts[0]
  const formattedDate = `${ds[4]}${ds[5]}${ds[0]}${ds[1]}${ds[2]}${ds[3]}`
  const batchId = parts[1]
  return `${formattedDate}-${batchId}`
}
