'use strict'

import FontFamilyDefinition from './b64-fonts'

FontFamilyDefinition.initialize()

const elements = {
  Barcode: require('./components/barcode.js'),
  Box: require('./components/box.js'),
  Circle: require('./components/circle.js'),
  Graphic: require('./components/graphic.js'),
  Grid: require('./components/grid.js'),
  Line: require('./components/line.js'),
  Raw: require('./components/raw.js'),
  SerialNumber: require('./components/serial-number.js'),
  Text: require('./components/text.js'),
}

export default {
  Alignment: require('./properties/alignment.js'),
  AlignmentValue: require('./enums/alignment-value.js'),
  Barcode: elements.Barcode,
  BarcodeRenderer: require('./helpers/barcode-renderer.js'),
  BarcodeType: require('./properties/barcode-type.js'),
  BarcodeTypeName: require('./enums/barcode-type-name.js'),

  Box: elements.Box,

  Circle: elements.Circle,
  FontFamily: require('./properties/font-family.js'),
  FontFamilyDefinition: FontFamilyDefinition,
  FontFamilyName: require('./enums/font-family-name.js'),

  Graphic: elements.Graphic,
  GraphicData: require('./properties/graphic-data.js'),
  Grid: elements.Grid,
  GridPosition: require('./properties/grid-position.js'),
  ImageProcessor: require('./helpers/image-processor.js'),
  ImageResizer: require('./helpers/image-resizer.js'),
  Label: require('./components/label.js'),
  LabelTools: require('./helpers/label-tools.js'),

  Line: elements.Line,
  PrintDensity: require('./properties/print-density.js'),
  PrintDensityName: require('./enums/print-density-name.js'),
  Raw: elements.Raw,
  Rotation: require('./enums/rotation.js'),
  SerialNumber: elements.SerialNumber,
  Size: require('./properties/size.js'),
  SizeType: require('./enums/size-type.js'),
  Spacing: require('./properties/spacing.js'),
  Text: elements.Text,

  elements: elements,
}
