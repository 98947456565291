import React, { FC, useEffect, useMemo, useState } from 'react'
import JSZPL from 'project/jszpl'
import InputGroup from './base/forms/InputGroup'
import { safeParseEventValue } from 'common/utils/forms/safeEventParseValue'
import dayjs from 'dayjs'
import { barcodeDateFormat } from 'common/utils/decodeBarcode'
import { parseInt, range, sortBy } from 'lodash'
import Button from './base/forms/Button'
import { toast } from './base/Toast'
import useActiveDeviceDetail from 'common/hooks/useActiveDeviceDetail'
import { Tabs } from './base/forms/Tabs'
import { useGetBatchesQuery } from 'common/services/useBatch'
import { InfoMessage } from './base/Messages'
import decodeBatchCode from 'common/utils/decodeBatchCode'
import { getStore } from 'common/store'
import { getMailQueueItemByBarcodes } from 'common/services/useMailQueueItemByBarcode'
import CustomerSearch from './CustomerSearch'
import ZPLItemActions from './ZPLItemActions'
import FilePicker from './FilePicker'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const PNG = require('pngjs').PNG
type ZPLItemType = {}
const {
  Barcode,
  BarcodeType,
  BarcodeTypeName,
  FontFamily,
  FontFamilyName,
  Graphic,
  GraphicData,
  Label,
  PrintDensity,
  PrintDensityName,
  Text,
} = JSZPL

const mmInAnInch = 25.4
const dpmm = 8
const inch = parseInt(`${mmInAnInch * dpmm}`)
const cmToInches = (cm: number) => cm / 2.54

const generateLabel = (widthInches: number, heightInches: number) => {
  const label = new Label()
  label.printDensity = new PrintDensity(PrintDensityName['8dpmm'])
  label.width = parseInt(`${widthInches * inch}`)
  label.height = parseInt(`${heightInches * inch}`)
  return label
}

const barcodeInitialWidth = 20
const barcodeItemWidth = 25
export const generateBarcodeData = (
  batch: number,
  document: number,
  date?: string,
) => {
  const dateString = dayjs(date).format(barcodeDateFormat)
  const passString = String(batch).padStart(2, '0')
  const batchString = String(document).padStart(4, '0')
  console.log(`${dateString}${passString}${batchString}`)
  return `${dateString}${passString}${batchString}`
}

const generateBarcode = ({
  data,
  label, // padded
  top, // padded
}: {
  top: number
  data: string
  label: any
}) => {
  const width = barcodeInitialWidth + data.length * barcodeItemWidth
  const left = indent
  const barcode = new Barcode()
  // @ts-ignore
  barcode.height = 80
  barcode.data = data
  // @ts-ignore
  barcode.width = 4 // Width is essentially the font size
  barcode.type = new BarcodeType(BarcodeTypeName.Interleaved25)
  // @ts-ignore
  barcode.left = left
  // @ts-ignore
  barcode.top = top
  return barcode
}

const generateText = (value: string) => {
  const text = new Text()
  text.text = value
  // text.characterWidth = fontSize
  // @ts-ignore
  text.fontFamily = new FontFamily(FontFamilyName.F)
  // text.characterHeight = text.characterWidth * 2
  return text
}

const lineHeight = 30
const indent = 80
const titlePadding = indent

export const getBarcodeData = (batch: number, doc: number, date?: string) => {
  const label = generateLabel(cmToInches(10), cmToInches(5))

  label.content.push(
    generateBarcode({
      data: generateBarcodeData(batch, doc, date),
      label,
      top: indent,
    }),
  )
  return label.generateZPL()
}
export const getCustomBarcodeData = (data: string) => {
  const label = generateLabel(cmToInches(10), cmToInches(5))

  label.content.push(
    generateBarcode({
      data,
      label,
      top: indent,
    }),
  )
  return label.generateZPL()
}

export const getAddressData = (addressParts: string[]) => {
  console.log(addressParts)
  const label = generateLabel(cmToInches(10), cmToInches(5))

  addressParts.map((v, i) => {
    const text = generateText(v)
    // @ts-ignore
    text.top = titlePadding + i * lineHeight
    // @ts-ignore
    text.left = indent
    label.content.push(text)
  })

  return label.generateZPL()
}

export const printLabel = (
  writeData: (data: string) => Promise<void>,
  data: string,
) => {
  return writeData(data)
    .then((res) => {
      toast('Printing action', null, 'success')
    })
    .catch((res) => {
      toast('Error', null, 'danger')
      console.error(res)
    })
}

const ZPLItem: FC<ZPLItemType> = ({}) => {
  const [ready, setReady] = useState(false)

  const [base64, setBase64] = useState('')

  const [barcode, setBarcode] = useState(dayjs().format('YYMMDD'))
  const [address, setAddress] = useState('')

  const { data } = useGetBatchesQuery({
    date_from: dayjs().format('YYYY-MM-DD'),
    date_to: dayjs().format('YYYY-MM-DD'),
  })

  const lastBatch = useMemo(() => {
    const day = data?.results?.[data?.results?.length - 1]
    if (!day) {
      return
    }
    const sortedData = sortBy(day.batches, (v) => {
      const job = decodeBatchCode(v.code)?.job
      return -job
    })
    return sortedData ? sortedData?.[0] : null
  }, [data])
  //
  const graphicPrinter = useMemo(() => {
    if (!base64) {
      return ''
    }
    const label = generateLabel(cmToInches(10), cmToInches(5))

    // @ts-ignore
    const imageBuffer = new Buffer.from(base64, 'base64')
    const imageData = PNG.sync.read(imageBuffer)

    const graphic = new Graphic()
    label.content.push(graphic)
    // @ts-ignore
    graphic.width = inch * 4
    // @ts-ignore
    graphic.top = 0
    // @ts-ignore
    graphic.height = inch * 2
    let index = 0
    const imageBits = []

    for (let y = 0; y < imageData.height; y++) {
      for (let x = 0; x < imageData.width; x++) {
        const red = imageData.data[index++]
        const green = imageData.data[index++]
        const blue = imageData.data[index++]
        const opacity = imageData.data[index++]

        let value = 0

        if (opacity != 0) {
          value = (red + green + blue) / 3 < 180 ? 1 : 0
        }

        imageBits.push(value)
      }
    }

    graphic.data = new GraphicData(imageData.width, imageData.height, imageBits)

    return label.generateZPL()
  }, [base64])

  useEffect(() => {
    setReady(true)
  }, [])
  const [saving, setSaving] = useState(false)
  const { writeData } = useActiveDeviceDetail()

  const sendData = function (message: string) {
    if (writeData) {
      setSaving(true)
      return printLabel(writeData, message).finally(() => {
        setSaving(false)
      })
    }
    return Promise.resolve()
  }
  const [start, setStart] = useState(1)

  const [batchNumber, setBatchNumber] = useState(1)
  const [numberToPrint, setNumberToPrint] = useState(1)

  const printBarcode = (barcode: string) => {
    if (barcode) {
      sendData(getCustomBarcodeData(barcode))
      setBarcode('')
    }
  }

  if (!ready) {
    return null
  }

  return (
    <div className=''>
      <Tabs
        uncontrolled
        tabLabels={[
          'No Reads',
          'Forwards',
          'Manual Print',
          'Customer Search',
          'Image',
        ]}
      >
        <div className='mt-2'>
          <h3>Print Batch</h3>
          {lastBatch && (
            <InfoMessage className='my-2'>
              The last known batch was batch{' '}
              {decodeBatchCode(lastBatch.code)?.job} created at{' '}
              {dayjs(lastBatch.created_at).format('HH:mm')}
            </InfoMessage>
          )}
          <div className='flex-row align-items-end'>
            <InputGroup
              title='Batch number'
              value={batchNumber}
              type='number'
              onChange={(e) => setBatchNumber(parseInt(safeParseEventValue(e)))}
            />

            <InputGroup
              className={'ms-2'}
              title='Start'
              value={start}
              type='number'
              onChange={(e) => setStart(parseInt(safeParseEventValue(e)))}
            />
            <InputGroup
              className={'ms-2'}
              title='End'
              value={numberToPrint}
              type='number'
              onChange={(e) =>
                setNumberToPrint(parseInt(safeParseEventValue(e)))
              }
            />
            <Button
              className='ms-2'
              onClick={async () => {
                const _range = range(start, numberToPrint + 1)
                const barcodes = _range.map((doc) => {
                  return generateBarcodeData(
                    batchNumber,
                    doc,
                    dayjs().toISOString(),
                  )
                })

                try {
                  await Promise.all(
                    barcodes.map((v) => {
                      getMailQueueItemByBarcodes(getStore(), { id: v }).then(
                        (res) => {
                          if (res.error) {
                            return Promise.resolve()
                          } else {
                            return Promise.reject(v)
                          }
                        },
                      )
                    }),
                  )
                } catch (e) {
                  toast(
                    `Mail Items already found in this batch: ${e}`,
                    null,
                    'danger',
                  )
                  return
                }

                for (let i = 0; i < _range.length; i++) {
                  const v = _range[i]
                  await sendData(getBarcodeData(batchNumber, v))
                }
              }}
            >
              Print
            </Button>
          </div>
        </div>
        <div className='mt-2'>
          <h3>Print Address</h3>
          <div className='flex-row'></div>
          <InputGroup
            textarea
            title='Address'
            onChange={(e) => setAddress(safeParseEventValue(e))}
          />
          <div className='text-end'>
            <Button
              className='mt-2'
              onClick={() => {
                sendData(
                  getAddressData(address.split(/[,\n]/).map((v) => v.trim())),
                )
              }}
            >
              Print
            </Button>
          </div>
        </div>
        <div className='mt-2'>
          <h3 className='mt-4'>Print Barcode</h3>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              printBarcode(barcode)
            }}
            className='flex-row align-items-end'
          >
            <InputGroup
              title='Barcode'
              value={barcode}
              type='number'
              onChange={(e) => setBarcode(safeParseEventValue(e))}
            />
            <Button className='ms-2' type='submit'>
              Print
            </Button>
          </form>
        </div>
        <div className='mt-2'>
          <CustomerSearch />
        </div>
        <div className='mt-2'>
          <h3>Print Batch</h3>
          <div className='flex-row my-4 align-items-center'>
            <span className='me-2 fw-bold text-nowrap'>Image - 4x4 inches</span>
            <FilePicker onChange={setBase64} />
          </div>

          <div className='flex-fill'>
            <ZPLItemActions value={graphicPrinter} />
          </div>
        </div>
      </Tabs>
    </div>
  )
}

export default ZPLItem
