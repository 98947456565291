import React, { FC } from 'react'

const FilePicker: FC<{
  onChange: (data: string) => void
  maxDimension?: number
}> = ({ maxDimension = 400, onChange }) => {
  const handleFileChange = (event: any) => {
    const file = event.target.files[0]

    if (file) {
      const reader = new FileReader()

      reader.onload = () => {
        const img = new Image()
        img.src = reader.result as string

        img.onload = () => {
          const canvas = document.createElement('canvas')
          let width = img.width
          let height = img.height

          if (width > maxDimension || height > maxDimension) {
            if (width > height) {
              height = (maxDimension / width) * height
              width = maxDimension
            } else {
              width = (maxDimension / height) * width
              height = maxDimension
            }
          }

          canvas.width = width
          canvas.height = height

          const ctx = canvas.getContext('2d')
          if (ctx) {
            ctx.drawImage(img, 0, 0, width, height)
          }

          const resizedBase64 = canvas.toDataURL('image/png') // You can specify other image formats as needed

          onChange(resizedBase64.split('base64,')[1])
        }
      }

      reader.readAsDataURL(file)
    }
  }

  return (
    <div>
      <input type='file' onChange={handleFileChange} />
    </div>
  )
}

export default FilePicker
