import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { FC, ReactNode } from 'react'
import classNames from 'classnames'

interface ModalDefault {
  title?: ReactNode
  size?: 'lg'
  isOpen: boolean
  sideModal?: boolean
  className?: string
  dialogClassName?: string
  onDismiss: () => void
  toggle: () => void
  centered?: boolean
  backdrop?: 'static' | boolean
}

const ModalDefault: FC<ModalDefault> = ({
  backdrop,
  centered,
  children,
  className,
  dialogClassName,
  isOpen,
  onDismiss,
  sideModal,
  size,
  title,
  toggle,
}) => {
  const onDismissClick = () => {
    if (onDismiss) {
      onDismiss()
    }
    toggle()
  }
  return (
    <Modal
      centered={centered}
      className={classNames(
        {
          'modal-dialog-lg': size === 'lg',
        },
        dialogClassName,
      )}
      modalClassName={sideModal ? 'side-modal' : undefined}
      unmountOnClose
      isOpen={isOpen}
      toggle={onDismissClick}
      backdrop={backdrop}
    >
      {!!title && <ModalHeader toggle={onDismissClick}>{title}</ModalHeader>}
      <ModalBody className={className}>{children}</ModalBody>
    </Modal>
  )
}

export default ModalDefault
