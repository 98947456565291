import { FC } from 'react'
import FuzzyHighlighter, { Highlighter } from 'react-fuzzy-highlighter'

type HighlightType = {
  query: string | null
  children: string
}

const Highlight: FC<HighlightType> = ({ children, query }) => {
  if (!children || !query || typeof children !== 'string') {
    return children
  }
  return (
    <FuzzyHighlighter
      query={query}
      data={[{ title: children }]}
      options={{
        distance: 100,
        includeMatches: true,
        keys: ['title'],
        location: 0,
        maxPatternLength: 999,
        minMatchCharLength: 4,
        shouldSort: true,
        threshold: 0.5,
      }}
    >
      {({ formattedResults }) => {
        if (!formattedResults.length) {
          return children
        }
        return formattedResults.map((formattedResult, resultIndex) => {
          if (formattedResult.formatted.title === undefined) {
            return null
          }

          return (
            <span key={resultIndex}>
              <Highlighter text={formattedResult.formatted.title} />
            </span>
          )
        })
      }}
    </FuzzyHighlighter>
  )
}

export default Highlight
