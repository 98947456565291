import { useEffect, useState } from 'react'

const useInputFocus = () => {
  const [isInputFocused, setIsInputFocused] = useState(false)

  const handleInputFocus = (event) => {
    if (event.target.tagName === 'INPUT' && event.target.type === 'text') {
      setIsInputFocused(true)
    }
  }

  const handleInputBlur = (event) => {
    if (event.target.tagName === 'INPUT' && event.target.type === 'text') {
      setIsInputFocused(false)
    }
  }

  useEffect(() => {
    document.body.addEventListener('focusin', handleInputFocus)
    document.body.addEventListener('focusout', handleInputBlur)

    return () => {
      document.body.removeEventListener('focusin', handleInputFocus)
      document.body.removeEventListener('focusout', handleInputBlur)
    }
  }, []) // Empty dependency array to run this effect only once

  return isInputFocused
}

export default useInputFocus
