import React, { FC, ReactElement, ReactNode } from 'react'
import { renderToString } from 'preact-render-to-string'
import ReactTooltip, { TooltipProps as _TooltipProps } from 'react-tooltip'
import { GUID } from 'common/utils/string/GUID'

type TooltipProps = {
  content: ReactNode
  place?: _TooltipProps['place']
  showIcon?: boolean
  children?: ReactNode
}

const Tooltip: FC<TooltipProps> = ({ children, content, place, showIcon }) => {
  const id = GUID()

  if (!content) {
    return <>{children}</>
  }

  const icon =
    showIcon && children ? (
      <span className='ms-1 fa fa-info-circle' data-for={id} data-tip />
    ) : null

  return (
    <>
      {children && (
        <span data-for={id} data-tip>
          {children}
          {icon}
        </span>
      )}
      {!children && (
        <span className='fa fa-info-circle' data-for={id} data-tip />
      )}
      {/* @ts-ignore */}
      <ReactTooltip
        html={typeof content === 'string'}
        className='rounded'
        id={id}
        place={place || 'top'}
        type='dark'
        effect='solid'
      >
        {content}
      </ReactTooltip>
    </>
  )
}

export default Tooltip
