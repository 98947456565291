import { FC } from 'react'

type LogoType = {}

const Logo: FC<LogoType> = ({}) => {
  return (
    <div className='flex-row align-items-center'>
      <svg
        width='32'
        height='32'
        viewBox='0 0 40 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20ZM20 36.748C29.235 36.748 36.7481 29.2348 36.7481 19.9999C36.7481 10.7649 29.2348 3.25198 20 3.25198C10.7651 3.25198 3.25198 10.7651 3.25198 20C3.24807 23.6009 4.41078 27.1061 6.56599 29.9908C8.21994 28.4703 9.73874 27.0245 11.2296 25.5686L11.2308 25.5656C5.09353 18.4247 7.65868 13.327 12.3952 8.66982L15.7329 12.0307L19.1856 8.40317C19.4278 8.14829 19.7179 7.94364 20.0393 7.80092C20.3606 7.6582 20.7069 7.5802 21.0584 7.57139C21.4099 7.56258 21.7597 7.62313 22.0878 7.74958C22.4159 7.87602 22.7159 8.06588 22.9705 8.30831C23.2252 8.55074 23.4296 8.84098 23.5721 9.16244C23.7145 9.48391 23.7922 9.83029 23.8007 10.1818C23.8093 10.5333 23.7484 10.8831 23.6217 11.211C23.495 11.539 23.3049 11.8388 23.0622 12.0933L19.5053 15.8298L21.6191 17.9582L23.9357 20.2912L27.3873 16.664C27.8766 16.1499 28.5501 15.8513 29.2596 15.8338C29.9691 15.8163 30.6564 16.0813 31.1705 16.5706C31.6846 17.0599 31.9832 17.7334 32.0007 18.4429C32.0182 19.1524 31.7532 19.8398 31.2639 20.3538L27.7084 24.0904L30.8433 27.2466C26.2292 31.8734 21.1997 34.3802 14.2795 28.5725C14.2766 28.5766 14.2754 28.5785 14.2722 28.5825C12.6135 30.2031 11.0604 31.6749 9.53533 33.0675C12.501 35.4532 16.1939 36.752 20 36.748Z'
          fill='#B82AA9'
        />
      </svg>
    </div>
  )
}

export default Logo
