import { FC } from 'react'
import classNames from 'classnames'

const themes = {
  danger: 'bg-danger text-white',
  info: 'bg-info text-white',
  primary: 'bg-light200 text-light700',
  secondary: 'bg-white badge-secondary text-body',
  success: 'bg-success text-white',
  warning: 'bg-warning text-white',
}

const sizes = {
  counter: 'badge--counter',
  default: '',
  dot: 'badge--dot',
}

type BadgeType = {
  size?: keyof typeof sizes
  className?: string
  theme?: keyof typeof themes
  onClick?: () => void
}

const Badge: FC<BadgeType> = ({
  children,
  className,
  onClick,
  size,
  theme = 'primary',
  ...rest
}) => {
  const themeClass = themes[theme]
  const sizeClass = size && sizes[size]

  return (
    <div
      onClick={onClick}
      className={classNames('badge fs-xs', themeClass, sizeClass, className)}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Badge
