import { useEffect, useRef } from 'react'
import onScan from 'onscan.js'
import useIsInputFocused from 'common/useIsInputFocused'
import useThrottle from 'common/hooks/useThrottle'

if (typeof document !== 'undefined') {
  onScan.attachTo(document, {
    stopPropagation: /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent),

    suffixKeyCodes: [],
  })
}
export default function useOnScan(cb: (v: string) => void, charLimit = 12) {
  const focused = useIsInputFocused()
  const isInputFocused = useRef(focused)
  useEffect(() => {
    isInputFocused.current = focused
  }, [focused])
  const cbRef = useRef(cb)
  useEffect(() => {
    cbRef.current = cb
  }, [cb])
  const onDone = useThrottle(function (e) {
    const scan = e?.detail?.scanCode
    if (scan?.length !== charLimit) {
      return
    }
    if (isInputFocused.current) {
      return
    }
    cbRef.current?.(scan)
  }, 1000)
  useEffect(() => {
    // @ts-ignore
    document.addEventListener('scan', onDone)

    return () => {
      //   @ts-ignore
      document.removeEventListener('scan', onDone)
    }
  }, [])
}
