import { omit } from 'common/utils/array/omit'

export function fromParam(url?: string): Record<string, any> {
  // {min:100,max:200} <- ?min=100&max=200
  const documentSearch =
    typeof document === 'undefined' ? '' : document.location.search
  let str
  if (url) {
    const split = url.split('/')
    str = split[split.length - 1]
  }

  if (!str && !documentSearch) {
    return {}
  }

  const urlString = (str || documentSearch).replace(/(^\?)/, '')
  try {
    const params = new URLSearchParams(urlString)
    const entries = params.entries() // returns an iterator of decoded [key,value] tuples
    const result: Record<string, any> = {}
    for (const [key, value] of entries) {
      // Check if the key already exists in the result object
      if (result.hasOwnProperty(key)) {
        // If it's not an array, convert it to an array
        if (!Array.isArray(result[key])) {
          result[key] = [result[key]]
        }
        // Push the new value into the array
        result[key].push(value)
      } else {
        // If the key does not exist, add it to the result
        result[key] = value
      }
    }
    return result
  } catch (e) {
    return {}
  }
}

export function toParam(obj?: Record<string, any>): string {
  // {min:100,max:200, prop:["1","2"]} -> ?min=100&max=200&prop=1&prop=2
  if (!obj) return ''

  const allDefined = omit(
    obj,
    Object.keys(obj).filter(
      (key) => typeof obj[key] === 'undefined' || obj[key] === null,
    ),
  )

  return Object.keys(allDefined)
    .map((k) => {
      // @ts-ignore
      const val = allDefined[k]

      if (Array.isArray(val)) {
        return val
          .map((v: any) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
          .join('&')
      } else {
        return `${encodeURIComponent(k)}=${encodeURIComponent(val)}`
      }
    })
    .join('&')
}
