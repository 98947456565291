import { useDevices } from 'common/services/useDevices'
import { useEffect, useMemo, useState } from 'react'
import { useActiveDevice } from 'common/services/useActiveDevice'
import { Device } from 'types/printer/device-type'

export default function () {
  const [status, setStatus] = useState('')
  const { Devices } = useDevices()

  const { activeDevice } = useActiveDevice()
  const {
    theDevice,
    writeData,
    zebra,
  }: {
    zebra?: typeof Zebra.Printer
    theDevice?: Device
    writeData?: (data: string) => Promise<any>
  } = useMemo(() => {
    const theDevice = Devices?.[activeDevice || 0]
    if (typeof activeDevice === 'number' && theDevice) {
      const zebra = new Zebra.Printer(theDevice)
      const writeData = (data: string) => {
        return zebra.isPrinterReady().then(function () {
          return new Promise((resolve, reject) => {
            theDevice.send(
              data,
              (data: any) => {
                resolve(data)
              },
              (err: any) => {
                reject(err)
              },
            )
          })
        })
      }
      return { theDevice, writeData, zebra }
    }
    return {}
  }, [activeDevice, Devices])

  useEffect(() => {
    setStatus('')
    zebra?.getStatus((status: any) => {
      setStatus(status.getMessage())
    })
  }, [zebra])

  return {
    device: theDevice,
    status,
    writeData,
    zebra,
  }
}
