export default function () {
  return (
    <svg
      width='107'
      height='107'
      viewBox='0 0 107 107'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_775_12659)'>
        <path d='M107 0H0V107H107V0Z' fill='white' />
        <path
          d='M9.88488 6.09253H6.09229V9.88513H9.88488V6.09253Z'
          fill='black'
        />
        <path
          d='M13.6775 6.09253H9.88488V9.88513H13.6775V6.09253Z'
          fill='black'
        />
        <path
          d='M17.4701 6.09253H13.6775V9.88513H17.4701V6.09253Z'
          fill='black'
        />
        <path
          d='M21.2627 6.09253H17.4701V9.88513H21.2627V6.09253Z'
          fill='black'
        />
        <path
          d='M25.0553 6.09253H21.2627V9.88513H25.0553V6.09253Z'
          fill='black'
        />
        <path
          d='M28.8479 6.09253H25.0553V9.88513H28.8479V6.09253Z'
          fill='black'
        />
        <path
          d='M32.6405 6.09253H28.8479V9.88513H32.6405V6.09253Z'
          fill='black'
        />
        <path
          d='M40.2257 6.09253H36.4331V9.88513H40.2257V6.09253Z'
          fill='black'
        />
        <path
          d='M44.0183 6.09253H40.2257V9.88513H44.0183V6.09253Z'
          fill='black'
        />
        <path
          d='M59.1886 6.09253H55.396V9.88513H59.1886V6.09253Z'
          fill='black'
        />
        <path
          d='M62.9812 6.09253H59.1886V9.88513H62.9812V6.09253Z'
          fill='black'
        />
        <path
          d='M66.7738 6.09253H62.9812V9.88513H66.7738V6.09253Z'
          fill='black'
        />
        <path
          d='M70.5664 6.09253H66.7738V9.88513H70.5664V6.09253Z'
          fill='black'
        />
        <path
          d='M78.1516 6.09253H74.359V9.88513H78.1516V6.09253Z'
          fill='black'
        />
        <path
          d='M81.9442 6.09253H78.1516V9.88513H81.9442V6.09253Z'
          fill='black'
        />
        <path
          d='M85.7368 6.09253H81.9442V9.88513H85.7368V6.09253Z'
          fill='black'
        />
        <path
          d='M89.5294 6.09253H85.7368V9.88513H89.5294V6.09253Z'
          fill='black'
        />
        <path
          d='M93.322 6.09253H89.5294V9.88513H93.322V6.09253Z'
          fill='black'
        />
        <path
          d='M97.1146 6.09253H93.322V9.88513H97.1146V6.09253Z'
          fill='black'
        />
        <path
          d='M100.907 6.09253H97.1146V9.88513H100.907V6.09253Z'
          fill='black'
        />
        <path
          d='M9.88488 9.88513H6.09229V13.6777H9.88488V9.88513Z'
          fill='black'
        />
        <path
          d='M32.6405 9.88513H28.8479L28.8479 13.6777H32.6405V9.88513Z'
          fill='black'
        />
        <path
          d='M44.0183 9.88513H40.2257V13.6777H44.0183V9.88513Z'
          fill='black'
        />
        <path
          d='M78.1516 9.88513H74.359V13.6777H78.1516V9.88513Z'
          fill='black'
        />
        <path
          d='M100.907 9.88513H97.1146V13.6777H100.907V9.88513Z'
          fill='black'
        />
        <path
          d='M9.88488 13.6777H6.09229V17.4703H9.88488V13.6777Z'
          fill='black'
        />
        <path
          d='M17.4701 13.6777H13.6775V17.4703H17.4701V13.6777Z'
          fill='black'
        />
        <path
          d='M21.2627 13.6777H17.4701V17.4703H21.2627V13.6777Z'
          fill='black'
        />
        <path
          d='M25.0553 13.6777H21.2627V17.4703H25.0553V13.6777Z'
          fill='black'
        />
        <path
          d='M32.6405 13.6777H28.8479V17.4703H32.6405V13.6777Z'
          fill='black'
        />
        <path
          d='M40.2257 13.6777H36.4331V17.4703H40.2257V13.6777Z'
          fill='black'
        />
        <path
          d='M44.0183 13.6777H40.2257V17.4703H44.0183V13.6777Z'
          fill='black'
        />
        <path
          d='M51.6035 13.6777H47.8109V17.4703H51.6035V13.6777Z'
          fill='black'
        />
        <path
          d='M55.396 13.6777H51.6035V17.4703H55.396V13.6777Z'
          fill='black'
        />
        <path
          d='M59.1886 13.6777H55.396V17.4703H59.1886V13.6777Z'
          fill='black'
        />
        <path
          d='M78.1516 13.6777H74.359V17.4703H78.1516V13.6777Z'
          fill='black'
        />
        <path
          d='M85.7368 13.6777H81.9442V17.4703H85.7368V13.6777Z'
          fill='black'
        />
        <path
          d='M89.5294 13.6777H85.7368V17.4703H89.5294V13.6777Z'
          fill='black'
        />
        <path
          d='M93.322 13.6777H89.5294V17.4703H93.322V13.6777Z'
          fill='black'
        />
        <path
          d='M100.907 13.6777H97.1146V17.4703H100.907V13.6777Z'
          fill='black'
        />
        <path
          d='M9.88488 17.4703H6.09229V21.2629H9.88488V17.4703Z'
          fill='black'
        />
        <path
          d='M17.4701 17.4703H13.6775V21.2629H17.4701V17.4703Z'
          fill='black'
        />
        <path
          d='M21.2627 17.4703H17.4701V21.2629H21.2627V17.4703Z'
          fill='black'
        />
        <path
          d='M25.0553 17.4703H21.2627V21.2629H25.0553V17.4703Z'
          fill='black'
        />
        <path
          d='M32.6405 17.4703H28.8479V21.2629H32.6405V17.4703Z'
          fill='black'
        />
        <path
          d='M40.2257 17.4703H36.4331V21.2629H40.2257V17.4703Z'
          fill='black'
        />
        <path
          d='M47.8109 17.4703H44.0183V21.2629H47.8109V17.4703Z'
          fill='black'
        />
        <path
          d='M55.396 17.4703H51.6035V21.2629H55.396V17.4703Z'
          fill='black'
        />
        <path
          d='M59.1886 17.4703H55.396V21.2629H59.1886V17.4703Z'
          fill='black'
        />
        <path
          d='M66.7738 17.4703H62.9813V21.2629H66.7738V17.4703Z'
          fill='black'
        />
        <path
          d='M78.1516 17.4703H74.359V21.2629H78.1516V17.4703Z'
          fill='black'
        />
        <path
          d='M85.7368 17.4703H81.9442V21.2629H85.7368V17.4703Z'
          fill='black'
        />
        <path
          d='M89.5294 17.4703H85.7368V21.2629H89.5294V17.4703Z'
          fill='black'
        />
        <path
          d='M93.322 17.4703H89.5294V21.2629H93.322V17.4703Z'
          fill='black'
        />
        <path
          d='M100.907 17.4703H97.1146V21.2629H100.907V17.4703Z'
          fill='black'
        />
        <path
          d='M9.88488 21.2629H6.09229V25.0555H9.88488V21.2629Z'
          fill='black'
        />
        <path
          d='M17.4701 21.2629H13.6775V25.0555H17.4701V21.2629Z'
          fill='black'
        />
        <path
          d='M21.2627 21.2629H17.4701V25.0555H21.2627V21.2629Z'
          fill='black'
        />
        <path
          d='M25.0553 21.2629H21.2627V25.0555H25.0553V21.2629Z'
          fill='black'
        />
        <path
          d='M32.6405 21.2629H28.8479V25.0555H32.6405V21.2629Z'
          fill='black'
        />
        <path
          d='M40.2257 21.2629H36.4331V25.0555H40.2257V21.2629Z'
          fill='black'
        />
        <path
          d='M51.6035 21.2629H47.8109V25.0555H51.6035V21.2629Z'
          fill='black'
        />
        <path
          d='M62.9813 21.2629H59.1886V25.0555H62.9812L62.9813 21.2629Z'
          fill='black'
        />
        <path
          d='M78.1516 21.2629H74.359V25.0555H78.1516V21.2629Z'
          fill='black'
        />
        <path
          d='M85.7368 21.2629H81.9442V25.0555H85.7368V21.2629Z'
          fill='black'
        />
        <path
          d='M89.5294 21.2629H85.7368V25.0555H89.5294V21.2629Z'
          fill='black'
        />
        <path
          d='M93.322 21.2629H89.5294V25.0555H93.322V21.2629Z'
          fill='black'
        />
        <path
          d='M100.907 21.2629H97.1146V25.0555H100.907V21.2629Z'
          fill='black'
        />
        <path
          d='M9.88488 25.0555H6.09229V28.8481H9.88488V25.0555Z'
          fill='black'
        />
        <path
          d='M32.6405 25.0555H28.8479V28.8481H32.6405V25.0555Z'
          fill='black'
        />
        <path
          d='M40.2257 25.0555H36.4331V28.8481H40.2257V25.0555Z'
          fill='black'
        />
        <path
          d='M47.8109 25.0555H44.0183V28.8481H47.8109V25.0555Z'
          fill='black'
        />
        <path
          d='M51.6035 25.0555H47.8109V28.8481H51.6035V25.0555Z'
          fill='black'
        />
        <path
          d='M59.1886 25.0555H55.396V28.8481H59.1886V25.0555Z'
          fill='black'
        />
        <path
          d='M66.7738 25.0555H62.9812L62.9813 28.8481H66.7738V25.0555Z'
          fill='black'
        />
        <path
          d='M70.5664 25.0555H66.7738V28.8481H70.5664V25.0555Z'
          fill='black'
        />
        <path
          d='M78.1516 25.0555H74.359V28.8481H78.1516V25.0555Z'
          fill='black'
        />
        <path
          d='M100.907 25.0555H97.1146V28.8481H100.907V25.0555Z'
          fill='black'
        />
        <path
          d='M9.88488 28.8481H6.09229V32.6407H9.88488V28.8481Z'
          fill='black'
        />
        <path
          d='M13.6775 28.8481L9.88488 28.8481V32.6407H13.6775V28.8481Z'
          fill='black'
        />
        <path
          d='M17.4701 28.8481H13.6775V32.6407H17.4701V28.8481Z'
          fill='black'
        />
        <path
          d='M21.2627 28.8481H17.4701V32.6407H21.2627V28.8481Z'
          fill='black'
        />
        <path
          d='M25.0553 28.8481H21.2627V32.6407H25.0553V28.8481Z'
          fill='black'
        />
        <path
          d='M28.8479 28.8481L25.0553 28.8481V32.6407H28.8479L28.8479 28.8481Z'
          fill='black'
        />
        <path
          d='M32.6405 28.8481H28.8479L28.8479 32.6407H32.6405V28.8481Z'
          fill='black'
        />
        <path
          d='M40.2257 28.8481H36.4331V32.6407H40.2257V28.8481Z'
          fill='black'
        />
        <path
          d='M47.8109 28.8481H44.0183V32.6407H47.8109V28.8481Z'
          fill='black'
        />
        <path
          d='M55.396 28.8481H51.6035V32.6407H55.396V28.8481Z'
          fill='black'
        />
        <path
          d='M62.9813 28.8481H59.1886V32.6407H62.9812L62.9813 28.8481Z'
          fill='black'
        />
        <path
          d='M70.5664 28.8481H66.7738V32.6407H70.5664V28.8481Z'
          fill='black'
        />
        <path
          d='M78.1516 28.8481H74.359V32.6407H78.1516V28.8481Z'
          fill='black'
        />
        <path
          d='M81.9442 28.8481L78.1516 28.8481V32.6407H81.9442V28.8481Z'
          fill='black'
        />
        <path
          d='M85.7368 28.8481H81.9442V32.6407H85.7368V28.8481Z'
          fill='black'
        />
        <path
          d='M89.5294 28.8481H85.7368V32.6407H89.5294V28.8481Z'
          fill='black'
        />
        <path
          d='M93.322 28.8481H89.5294V32.6407H93.322V28.8481Z'
          fill='black'
        />
        <path
          d='M97.1146 28.8481L93.322 28.8481V32.6407H97.1146V28.8481Z'
          fill='black'
        />
        <path
          d='M100.907 28.8481H97.1146V32.6407H100.907V28.8481Z'
          fill='black'
        />
        <path
          d='M59.1886 32.6407H55.396V36.4333H59.1886V32.6407Z'
          fill='black'
        />
        <path
          d='M62.9812 32.6407H59.1886V36.4333H62.9812V32.6407Z'
          fill='black'
        />
        <path
          d='M66.7738 32.6407H62.9812V36.4333H66.7738V32.6407Z'
          fill='black'
        />
        <path
          d='M17.4701 36.4333H13.6775V40.2259H17.4701V36.4333Z'
          fill='black'
        />
        <path
          d='M21.2627 36.4333H17.4701V40.2259H21.2627V36.4333Z'
          fill='black'
        />
        <path
          d='M25.0553 36.4333H21.2627V40.2259H25.0553V36.4333Z'
          fill='black'
        />
        <path
          d='M28.8479 36.4333H25.0553V40.2259H28.8479V36.4333Z'
          fill='black'
        />
        <path
          d='M32.6405 36.4333H28.8479V40.2259H32.6405V36.4333Z'
          fill='black'
        />
        <path
          d='M36.4331 36.4333H32.6405V40.2259H36.4331V36.4333Z'
          fill='black'
        />
        <path
          d='M47.8109 36.4333H44.0183V40.2259H47.8109V36.4333Z'
          fill='black'
        />
        <path
          d='M55.396 36.4333H51.6035V40.2259H55.396V36.4333Z'
          fill='black'
        />
        <path
          d='M62.9812 36.4333H59.1886V40.2259H62.9812V36.4333Z'
          fill='black'
        />
        <path
          d='M66.7738 36.4333H62.9812V40.2259H66.7738V36.4333Z'
          fill='black'
        />
        <path
          d='M70.5664 36.4333H66.7738V40.2259H70.5664V36.4333Z'
          fill='black'
        />
        <path
          d='M74.359 36.4333H70.5664V40.2259H74.359V36.4333Z'
          fill='black'
        />
        <path
          d='M81.9442 36.4333H78.1516V40.2259H81.9442V36.4333Z'
          fill='black'
        />
        <path
          d='M85.7368 36.4333H81.9442V40.2259H85.7368V36.4333Z'
          fill='black'
        />
        <path
          d='M89.5294 36.4333H85.7368V40.2259H89.5294V36.4333Z'
          fill='black'
        />
        <path
          d='M93.322 36.4333H89.5294V40.2259H93.322V36.4333Z'
          fill='black'
        />
        <path
          d='M100.907 36.4333H97.1146V40.2259H100.907V36.4333Z'
          fill='black'
        />
        <path
          d='M13.6775 40.2259H9.88488V44.0185H13.6775V40.2259Z'
          fill='black'
        />
        <path
          d='M28.8479 40.2259H25.0553V44.0185H28.8479V40.2259Z'
          fill='black'
        />
        <path
          d='M51.6035 40.2259H47.8109V44.0185H51.6035V40.2259Z'
          fill='black'
        />
        <path
          d='M59.1886 40.2259H55.396V44.0185H59.1886V40.2259Z'
          fill='black'
        />
        <path
          d='M62.9812 40.2259H59.1886V44.0185H62.9812V40.2259Z'
          fill='black'
        />
        <path
          d='M81.9442 40.2259H78.1516V44.0185H81.9442V40.2259Z'
          fill='black'
        />
        <path
          d='M89.5294 40.2259H85.7368V44.0185H89.5294V40.2259Z'
          fill='black'
        />
        <path
          d='M97.1146 40.2259H93.322V44.0185H97.1146V40.2259Z'
          fill='black'
        />
        <path
          d='M9.88488 44.0185H6.09229V47.8111H9.88488V44.0185Z'
          fill='black'
        />
        <path
          d='M17.4701 44.0185H13.6775V47.8111H17.4701V44.0185Z'
          fill='black'
        />
        <path
          d='M21.2627 44.0185H17.4701V47.8111H21.2627V44.0185Z'
          fill='black'
        />
        <path
          d='M32.6405 44.0185H28.8479L28.8479 47.8111H32.6405V44.0185Z'
          fill='black'
        />
        <path
          d='M40.2257 44.0185H36.4331V47.8111H40.2257V44.0185Z'
          fill='black'
        />
        <path
          d='M44.0183 44.0185H40.2257V47.8111H44.0183V44.0185Z'
          fill='black'
        />
        <path
          d='M47.8109 44.0185H44.0183V47.8111H47.8109V44.0185Z'
          fill='black'
        />
        <path
          d='M74.359 44.0185H70.5664V47.8111H74.359V44.0185Z'
          fill='black'
        />
        <path
          d='M78.1516 44.0185H74.359V47.8111H78.1516V44.0185Z'
          fill='black'
        />
        <path
          d='M89.5294 44.0185H85.7368V47.8111H89.5294V44.0185Z'
          fill='black'
        />
        <path
          d='M100.907 44.0185H97.1146V47.8111H100.907V44.0185Z'
          fill='black'
        />
        <path
          d='M9.88488 47.8111H6.09229V51.6037H9.88488V47.8111Z'
          fill='black'
        />
        <path
          d='M17.4701 47.8111H13.6775V51.6037H17.4701V47.8111Z'
          fill='black'
        />
        <path
          d='M25.0553 47.8111H21.2627V51.6037H25.0553V47.8111Z'
          fill='black'
        />
        <path
          d='M28.8479 47.8111H25.0553V51.6037H28.8479L28.8479 47.8111Z'
          fill='black'
        />
        <path
          d='M36.4331 47.8111H32.6405V51.6037H36.4331V47.8111Z'
          fill='black'
        />
        <path
          d='M40.2257 47.8111H36.4331V51.6037H40.2257V47.8111Z'
          fill='black'
        />
        <path
          d='M44.0183 47.8111H40.2257V51.6037H44.0183V47.8111Z'
          fill='black'
        />
        <path
          d='M47.8109 47.8111H44.0183V51.6037H47.8109V47.8111Z'
          fill='black'
        />
        <path
          d='M55.396 47.8111H51.6035V51.6037H55.396V47.8111Z'
          fill='black'
        />
        <path
          d='M62.9812 47.8111H59.1886V51.6037H62.9812V47.8111Z'
          fill='black'
        />
        <path
          d='M66.7738 47.8111H62.9812V51.6037H66.7738V47.8111Z'
          fill='black'
        />
        <path
          d='M70.5664 47.8111H66.7738V51.6037H70.5664V47.8111Z'
          fill='black'
        />
        <path
          d='M93.322 47.8111H89.5294V51.6037H93.322V47.8111Z'
          fill='black'
        />
        <path
          d='M97.1146 47.8111H93.322V51.6037H97.1146V47.8111Z'
          fill='black'
        />
        <path
          d='M100.907 47.8111H97.1146V51.6037H100.907V47.8111Z'
          fill='black'
        />
        <path
          d='M13.6775 51.6037H9.88488V55.3963H13.6775V51.6037Z'
          fill='black'
        />
        <path
          d='M21.2627 51.6037H17.4701V55.3963H21.2627V51.6037Z'
          fill='black'
        />
        <path
          d='M25.0553 51.6037H21.2627V55.3963H25.0553V51.6037Z'
          fill='black'
        />
        <path
          d='M28.8479 51.6037H25.0553V55.3963H28.8479V51.6037Z'
          fill='black'
        />
        <path
          d='M32.6405 51.6037H28.8479V55.3963H32.6405V51.6037Z'
          fill='black'
        />
        <path
          d='M40.2257 51.6037H36.4331V55.3963H40.2257V51.6037Z'
          fill='black'
        />
        <path
          d='M44.0183 51.6037H40.2257V55.3963H44.0183V51.6037Z'
          fill='black'
        />
        <path
          d='M51.6035 51.6037H47.8109V55.3963H51.6035V51.6037Z'
          fill='black'
        />
        <path
          d='M55.396 51.6037H51.6035V55.3963H55.396V51.6037Z'
          fill='black'
        />
        <path
          d='M62.9812 51.6037H59.1886V55.3963H62.9812V51.6037Z'
          fill='black'
        />
        <path
          d='M74.359 51.6037H70.5664V55.3963H74.359V51.6037Z'
          fill='black'
        />
        <path
          d='M81.9442 51.6037H78.1516V55.3963H81.9442V51.6037Z'
          fill='black'
        />
        <path
          d='M85.7368 51.6037H81.9442V55.3963H85.7368V51.6037Z'
          fill='black'
        />
        <path
          d='M93.322 51.6037H89.5294V55.3963H93.322V51.6037Z'
          fill='black'
        />
        <path
          d='M9.88488 55.3963H6.09229V59.1889H9.88488V55.3963Z'
          fill='black'
        />
        <path
          d='M13.6775 55.3963H9.88488V59.1889H13.6775V55.3963Z'
          fill='black'
        />
        <path
          d='M17.4701 55.3963H13.6775V59.1889H17.4701V55.3963Z'
          fill='black'
        />
        <path
          d='M44.0183 55.3963H40.2257V59.1889H44.0183V55.3963Z'
          fill='black'
        />
        <path
          d='M47.8109 55.3963H44.0183V59.1889H47.8109V55.3963Z'
          fill='black'
        />
        <path
          d='M51.6035 55.3963H47.8109V59.1889H51.6035V55.3963Z'
          fill='black'
        />
        <path
          d='M74.359 55.3963H70.5664V59.1889H74.359V55.3963Z'
          fill='black'
        />
        <path
          d='M78.1516 55.3963H74.359V59.1889H78.1516V55.3963Z'
          fill='black'
        />
        <path
          d='M81.9442 55.3963H78.1516V59.1889H81.9442V55.3963Z'
          fill='black'
        />
        <path
          d='M9.88488 59.1889H6.09229V62.9815H9.88488V59.1889Z'
          fill='black'
        />
        <path
          d='M13.6775 59.1889H9.88488V62.9815H13.6775V59.1889Z'
          fill='black'
        />
        <path
          d='M21.2627 59.1889H17.4701V62.9815H21.2627V59.1889Z'
          fill='black'
        />
        <path
          d='M32.6405 59.1889H28.8479V62.9815H32.6405V59.1889Z'
          fill='black'
        />
        <path
          d='M36.4331 59.1889H32.6405V62.9815H36.4331V59.1889Z'
          fill='black'
        />
        <path
          d='M44.0183 59.1889H40.2257V62.9815H44.0183V59.1889Z'
          fill='black'
        />
        <path
          d='M47.8109 59.1889H44.0183V62.9815H47.8109V59.1889Z'
          fill='black'
        />
        <path
          d='M55.396 59.1889H51.6035V62.9815H55.396V59.1889Z'
          fill='black'
        />
        <path
          d='M66.7738 59.1889H62.9813V62.9815H66.7738V59.1889Z'
          fill='black'
        />
        <path
          d='M74.359 59.1889H70.5664V62.9815H74.359V59.1889Z'
          fill='black'
        />
        <path
          d='M78.1516 59.1889H74.359V62.9815H78.1516V59.1889Z'
          fill='black'
        />
        <path
          d='M85.7368 59.1889H81.9442V62.9815H85.7368V59.1889Z'
          fill='black'
        />
        <path
          d='M97.1146 59.1889H93.322V62.9815H97.1146V59.1889Z'
          fill='black'
        />
        <path
          d='M9.88488 62.9815H6.09229V66.7741H9.88488V62.9815Z'
          fill='black'
        />
        <path
          d='M13.6775 62.9815H9.88488V66.7741H13.6775V62.9815Z'
          fill='black'
        />
        <path
          d='M17.4701 62.9815H13.6775V66.7741H17.4701V62.9815Z'
          fill='black'
        />
        <path
          d='M21.2627 62.9815H17.4701V66.7741H21.2627V62.9815Z'
          fill='black'
        />
        <path
          d='M25.0553 62.9815L21.2627 62.9815V66.7741H25.0553V62.9815Z'
          fill='black'
        />
        <path
          d='M44.0183 62.9815H40.2257V66.7741H44.0183V62.9815Z'
          fill='black'
        />
        <path
          d='M47.8109 62.9815H44.0183V66.7741H47.8109V62.9815Z'
          fill='black'
        />
        <path
          d='M51.6035 62.9815H47.8109V66.7741H51.6035V62.9815Z'
          fill='black'
        />
        <path
          d='M62.9813 62.9815L59.1886 62.9815V66.7741H62.9812L62.9813 62.9815Z'
          fill='black'
        />
        <path
          d='M74.359 62.9815H70.5664V66.7741H74.359V62.9815Z'
          fill='black'
        />
        <path
          d='M85.7368 62.9815H81.9442V66.7741H85.7368V62.9815Z'
          fill='black'
        />
        <path
          d='M93.322 62.9815L89.5294 62.9815V66.7741H93.322V62.9815Z'
          fill='black'
        />
        <path
          d='M97.1146 62.9815H93.322V66.7741H97.1146V62.9815Z'
          fill='black'
        />
        <path
          d='M13.6775 66.7741H9.88488V70.5667H13.6775V66.7741Z'
          fill='black'
        />
        <path
          d='M25.0553 66.7741H21.2627V70.5667H25.0553V66.7741Z'
          fill='black'
        />
        <path
          d='M32.6405 66.7741H28.8479V70.5667H32.6405V66.7741Z'
          fill='black'
        />
        <path
          d='M36.4331 66.7741H32.6405V70.5667H36.4331V66.7741Z'
          fill='black'
        />
        <path
          d='M47.8109 66.7741H44.0183V70.5667H47.8109V66.7741Z'
          fill='black'
        />
        <path
          d='M51.6035 66.7741H47.8109V70.5667H51.6035V66.7741Z'
          fill='black'
        />
        <path
          d='M59.1886 66.7741H55.396V70.5667H59.1886V66.7741Z'
          fill='black'
        />
        <path
          d='M62.9812 66.7741H59.1886V70.5667H62.9812V66.7741Z'
          fill='black'
        />
        <path
          d='M66.7738 66.7741H62.9812V70.5667H66.7738V66.7741Z'
          fill='black'
        />
        <path
          d='M70.5664 66.7741H66.7738V70.5667H70.5664V66.7741Z'
          fill='black'
        />
        <path
          d='M74.359 66.7741H70.5664V70.5667H74.359V66.7741Z'
          fill='black'
        />
        <path
          d='M78.1516 66.7741H74.359V70.5667H78.1516V66.7741Z'
          fill='black'
        />
        <path
          d='M81.9442 66.7741H78.1516V70.5667H81.9442V66.7741Z'
          fill='black'
        />
        <path
          d='M85.7368 66.7741H81.9442V70.5667H85.7368V66.7741Z'
          fill='black'
        />
        <path
          d='M89.5294 66.7741H85.7368V70.5667H89.5294V66.7741Z'
          fill='black'
        />
        <path
          d='M97.1146 66.7741H93.322V70.5667H97.1146V66.7741Z'
          fill='black'
        />
        <path
          d='M51.6035 70.5667H47.8109V74.3593H51.6035V70.5667Z'
          fill='black'
        />
        <path
          d='M55.396 70.5667H51.6035V74.3593H55.396V70.5667Z'
          fill='black'
        />
        <path
          d='M59.1886 70.5667H55.396V74.3593H59.1886V70.5667Z'
          fill='black'
        />
        <path
          d='M66.7738 70.5667H62.9812L62.9813 74.3593H66.7738V70.5667Z'
          fill='black'
        />
        <path
          d='M70.5664 70.5667H66.7738V74.3593H70.5664V70.5667Z'
          fill='black'
        />
        <path
          d='M85.7368 70.5667H81.9442V74.3593H85.7368V70.5667Z'
          fill='black'
        />
        <path
          d='M97.1146 70.5667H93.322V74.3593H97.1146V70.5667Z'
          fill='black'
        />
        <path
          d='M9.88488 74.3593H6.09229V78.1519H9.88488V74.3593Z'
          fill='black'
        />
        <path
          d='M13.6775 74.3593H9.88488V78.1519H13.6775V74.3593Z'
          fill='black'
        />
        <path
          d='M17.4701 74.3593H13.6775V78.1519H17.4701V74.3593Z'
          fill='black'
        />
        <path
          d='M21.2627 74.3593H17.4701V78.1519H21.2627V74.3593Z'
          fill='black'
        />
        <path
          d='M25.0553 74.3593H21.2627V78.1519H25.0553V74.3593Z'
          fill='black'
        />
        <path
          d='M28.8479 74.3593H25.0553V78.1519H28.8479V74.3593Z'
          fill='black'
        />
        <path
          d='M32.6405 74.3593H28.8479V78.1519H32.6405V74.3593Z'
          fill='black'
        />
        <path
          d='M40.2257 74.3593H36.4331V78.1519H40.2257V74.3593Z'
          fill='black'
        />
        <path
          d='M51.6035 74.3593H47.8109V78.1519H51.6035V74.3593Z'
          fill='black'
        />
        <path
          d='M55.396 74.3593H51.6035V78.1519H55.396V74.3593Z'
          fill='black'
        />
        <path
          d='M62.9813 74.3593H59.1886V78.1519H62.9812L62.9813 74.3593Z'
          fill='black'
        />
        <path
          d='M70.5664 74.3593H66.7738V78.1519H70.5664V74.3593Z'
          fill='black'
        />
        <path
          d='M78.1516 74.3593H74.359V78.1519H78.1516V74.3593Z'
          fill='black'
        />
        <path
          d='M85.7368 74.3593H81.9442V78.1519H85.7368V74.3593Z'
          fill='black'
        />
        <path
          d='M89.5294 74.3593H85.7368V78.1519H89.5294V74.3593Z'
          fill='black'
        />
        <path
          d='M9.88488 78.1519H6.09229V81.9445H9.88488V78.1519Z'
          fill='black'
        />
        <path
          d='M32.6405 78.1519H28.8479L28.8479 81.9445H32.6405V78.1519Z'
          fill='black'
        />
        <path
          d='M40.2257 78.1519H36.4331V81.9445H40.2257V78.1519Z'
          fill='black'
        />
        <path
          d='M44.0183 78.1519H40.2257V81.9445H44.0183V78.1519Z'
          fill='black'
        />
        <path
          d='M55.396 78.1519H51.6035V81.9445H55.396V78.1519Z'
          fill='black'
        />
        <path
          d='M59.1886 78.1519H55.396V81.9445H59.1886V78.1519Z'
          fill='black'
        />
        <path
          d='M62.9812 78.1519H59.1886V81.9445H62.9812V78.1519Z'
          fill='black'
        />
        <path
          d='M70.5664 78.1519H66.7738V81.9445H70.5664V78.1519Z'
          fill='black'
        />
        <path
          d='M85.7368 78.1519H81.9442V81.9445H85.7368V78.1519Z'
          fill='black'
        />
        <path
          d='M93.322 78.1519H89.5294V81.9445H93.322V78.1519Z'
          fill='black'
        />
        <path
          d='M100.907 78.1519H97.1146V81.9445H100.907V78.1519Z'
          fill='black'
        />
        <path
          d='M9.88488 81.9445H6.09229V85.7371H9.88488V81.9445Z'
          fill='black'
        />
        <path
          d='M17.4701 81.9445H13.6775V85.7371H17.4701V81.9445Z'
          fill='black'
        />
        <path
          d='M21.2627 81.9445H17.4701V85.7371H21.2627V81.9445Z'
          fill='black'
        />
        <path
          d='M25.0553 81.9445H21.2627V85.7371H25.0553V81.9445Z'
          fill='black'
        />
        <path
          d='M32.6405 81.9445H28.8479V85.7371H32.6405V81.9445Z'
          fill='black'
        />
        <path
          d='M40.2257 81.9445H36.4331V85.7371H40.2257V81.9445Z'
          fill='black'
        />
        <path
          d='M47.8109 81.9445H44.0183V85.7371H47.8109V81.9445Z'
          fill='black'
        />
        <path
          d='M55.396 81.9445H51.6035V85.7371H55.396V81.9445Z'
          fill='black'
        />
        <path
          d='M70.5664 81.9445H66.7738V85.7371H70.5664V81.9445Z'
          fill='black'
        />
        <path
          d='M74.359 81.9445H70.5664V85.7371H74.359V81.9445Z'
          fill='black'
        />
        <path
          d='M78.1516 81.9445H74.359V85.7371H78.1516V81.9445Z'
          fill='black'
        />
        <path
          d='M81.9442 81.9445H78.1516V85.7371H81.9442V81.9445Z'
          fill='black'
        />
        <path
          d='M85.7368 81.9445H81.9442V85.7371H85.7368V81.9445Z'
          fill='black'
        />
        <path
          d='M89.5294 81.9445H85.7368V85.7371H89.5294V81.9445Z'
          fill='black'
        />
        <path
          d='M97.1146 81.9445H93.322V85.7371H97.1146V81.9445Z'
          fill='black'
        />
        <path
          d='M9.88488 85.7371H6.09229V89.5297H9.88488V85.7371Z'
          fill='black'
        />
        <path
          d='M17.4701 85.7371H13.6775V89.5297H17.4701V85.7371Z'
          fill='black'
        />
        <path
          d='M21.2627 85.7371H17.4701V89.5297H21.2627V85.7371Z'
          fill='black'
        />
        <path
          d='M25.0553 85.7371H21.2627V89.5297H25.0553V85.7371Z'
          fill='black'
        />
        <path
          d='M32.6405 85.7371H28.8479V89.5297H32.6405V85.7371Z'
          fill='black'
        />
        <path
          d='M40.2257 85.7371H36.4331V89.5297H40.2257V85.7371Z'
          fill='black'
        />
        <path
          d='M44.0183 85.7371H40.2257V89.5297H44.0183V85.7371Z'
          fill='black'
        />
        <path
          d='M55.396 85.7371H51.6035V89.5297H55.396V85.7371Z'
          fill='black'
        />
        <path
          d='M62.9812 85.7371H59.1886V89.5297H62.9812V85.7371Z'
          fill='black'
        />
        <path
          d='M70.5664 85.7371H66.7738V89.5297H70.5664V85.7371Z'
          fill='black'
        />
        <path
          d='M78.1516 85.7371H74.359V89.5297H78.1516V85.7371Z'
          fill='black'
        />
        <path
          d='M85.7368 85.7371H81.9442V89.5297H85.7368V85.7371Z'
          fill='black'
        />
        <path
          d='M89.5294 85.7371H85.7368V89.5297H89.5294V85.7371Z'
          fill='black'
        />
        <path
          d='M100.907 85.7371H97.1146V89.5297H100.907V85.7371Z'
          fill='black'
        />
        <path
          d='M9.88488 89.5297H6.09229V93.3223H9.88488V89.5297Z'
          fill='black'
        />
        <path
          d='M17.4701 89.5297H13.6775V93.3223H17.4701V89.5297Z'
          fill='black'
        />
        <path
          d='M21.2627 89.5297H17.4701V93.3223H21.2627V89.5297Z'
          fill='black'
        />
        <path
          d='M25.0553 89.5297H21.2627V93.3223H25.0553V89.5297Z'
          fill='black'
        />
        <path
          d='M32.6405 89.5297H28.8479V93.3223H32.6405V89.5297Z'
          fill='black'
        />
        <path
          d='M40.2257 89.5297H36.4331V93.3223H40.2257V89.5297Z'
          fill='black'
        />
        <path
          d='M55.396 89.5297H51.6035V93.3223H55.396V89.5297Z'
          fill='black'
        />
        <path
          d='M59.1886 89.5297H55.396V93.3223H59.1886V89.5297Z'
          fill='black'
        />
        <path
          d='M62.9812 89.5297H59.1886V93.3223H62.9812V89.5297Z'
          fill='black'
        />
        <path
          d='M70.5664 89.5297H66.7738V93.3223H70.5664V89.5297Z'
          fill='black'
        />
        <path
          d='M85.7368 89.5297H81.9442V93.3223H85.7368V89.5297Z'
          fill='black'
        />
        <path
          d='M100.907 89.5297H97.1146V93.3223H100.907V89.5297Z'
          fill='black'
        />
        <path
          d='M9.88488 93.3223H6.09229V97.1149H9.88488V93.3223Z'
          fill='black'
        />
        <path
          d='M32.6405 93.3223H28.8479V97.1149H32.6405V93.3223Z'
          fill='black'
        />
        <path
          d='M47.8109 93.3223H44.0183V97.1149H47.8109V93.3223Z'
          fill='black'
        />
        <path
          d='M55.396 93.3223H51.6035V97.1149H55.396V93.3223Z'
          fill='black'
        />
        <path
          d='M62.9812 93.3223H59.1886V97.1149H62.9812V93.3223Z'
          fill='black'
        />
        <path
          d='M66.7738 93.3223H62.9812V97.1149H66.7738V93.3223Z'
          fill='black'
        />
        <path
          d='M78.1516 93.3223H74.359V97.1149H78.1516V93.3223Z'
          fill='black'
        />
        <path
          d='M81.9442 93.3223H78.1516V97.1149H81.9442V93.3223Z'
          fill='black'
        />
        <path
          d='M85.7368 93.3223H81.9442V97.1149H85.7368V93.3223Z'
          fill='black'
        />
        <path
          d='M100.907 93.3223H97.1146V97.1149H100.907V93.3223Z'
          fill='black'
        />
        <path
          d='M9.88488 97.1149H6.09229V100.907H9.88488V97.1149Z'
          fill='black'
        />
        <path
          d='M13.6775 97.1149H9.88488V100.907H13.6775V97.1149Z'
          fill='black'
        />
        <path
          d='M17.4701 97.1149H13.6775V100.907H17.4701V97.1149Z'
          fill='black'
        />
        <path
          d='M21.2627 97.1149H17.4701V100.907H21.2627V97.1149Z'
          fill='black'
        />
        <path
          d='M25.0553 97.1149H21.2627V100.907H25.0553V97.1149Z'
          fill='black'
        />
        <path
          d='M28.8479 97.1149H25.0553V100.907H28.8479L28.8479 97.1149Z'
          fill='black'
        />
        <path
          d='M32.6405 97.1149H28.8479L28.8479 100.907H32.6405V97.1149Z'
          fill='black'
        />
        <path
          d='M47.8109 97.1149H44.0183V100.907H47.8109V97.1149Z'
          fill='black'
        />
        <path
          d='M62.9812 97.1149H59.1886V100.907H62.9812V97.1149Z'
          fill='black'
        />
        <path
          d='M66.7738 97.1149H62.9812V100.907H66.7738V97.1149Z'
          fill='black'
        />
        <path
          d='M70.5664 97.1149H66.7738V100.907H70.5664V97.1149Z'
          fill='black'
        />
        <path
          d='M78.1516 97.1149H74.359V100.907H78.1516V97.1149Z'
          fill='black'
        />
        <path
          d='M89.5294 97.1149H85.7368V100.907H89.5294V97.1149Z'
          fill='black'
        />
        <path
          d='M93.322 97.1149H89.5294V100.907H93.322V97.1149Z'
          fill='black'
        />
        <path
          d='M97.1146 97.1149H93.322V100.907H97.1146V97.1149Z'
          fill='black'
        />
        <path
          d='M100.907 97.1149H97.1146V100.907H100.907V97.1149Z'
          fill='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_775_12659'>
          <rect width='107' height='107' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
