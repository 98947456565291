export const formatMailLocation = (location: string) => {
  if (!location) return ''
  const prefix = location.slice(0, 2)
  const datePart = location.slice(3, 9)
  const timePart = location.slice(9, 11)
  const idPart = location.slice(11)

  const formattedDate = `${datePart.slice(0, 2)}-${datePart.slice(
    2,
    4,
  )}-${datePart.slice(4, 6)}`

  return `${prefix} ${formattedDate} ${timePart} ${idPart}`
}
