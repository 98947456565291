import Link from 'next/link'
import { useRouter } from 'next/router'
import { Children, cloneElement, FC } from 'react'

interface ActiveLinkType {
  href?: string
  match?: any
  exact?: boolean
  as?: string
  disabled?: boolean
  activeClassName?: string
}

const ActiveLink: FC<ActiveLinkType> = ({
  href = '',
  match,
  as = href,
  disabled,
  activeClassName = 'active',
  exact,
  children,
}) => {
  const router = useRouter()
  const child = Children.only(children)
  // @ts-ignore
  let className = child.props.className || ''

  const isMatch = exact
    ? !match && router.asPath === href && activeClassName
    : !match && router.asPath.includes(href)
  if (isMatch || (match && (router.asPath || router.pathname).match(match))) {
    className = `${className} ${activeClassName}`.trim()
  }

  if (disabled) {
    return <>child</>
  }
  return (
    <Link as={as} href={href}>
      {/*@ts-ignore*/}
      {cloneElement(child, { className })}
    </Link>
  )
}

export default ActiveLink
