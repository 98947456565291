import { FC, useMemo } from 'react'
import Card from './base/Card'

type StepType = {
  title: string
  subtitle: string
  value: number
  currentValue: number
}
type StepIconType = {
  status: 'COMPLETED' | 'IN_PROGRESS' | 'UPCOMING'
}
const StepIcon: FC<StepIconType> = ({ status }) => (
  <>
    {status === 'COMPLETED' && (
      <svg
        width='50'
        height='50'
        viewBox='0 0 65 64'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect x='0.129395' width='64.4364' height='64' rx='32' fill='#B839A9' />
        <path
          d='M45.1562 22.7852C45.7422 23.4492 45.7422 24.0938 45.1562 24.7188L29.6875 40.1875C29.0625 40.7734 28.418 40.7734 27.7539 40.1875L19.7852 32.2188C19.2383 31.5938 19.2383 30.9492 19.7852 30.2852C20.4492 29.7383 21.1133 29.7383 21.7773 30.2852L28.75 37.2578L43.2227 22.7852C43.8867 22.2383 44.5312 22.2383 45.1562 22.7852Z'
          fill='white'
        />
      </svg>
    )}
    {status === 'IN_PROGRESS' && (
      <svg
        width='50'
        height='50'
        viewBox='0 0 65 64'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect x='0.129395' width='64.4364' height='64' rx='32' fill='#DBDBDB' />
        <path
          d='M47.5 31.75C47.4609 34.5625 46.7773 37.082 45.4492 39.3086C44.1211 41.5742 42.3242 43.3711 40.0586 44.6992C37.832 46.0273 35.3125 46.7109 32.5 46.75C29.6875 46.7109 27.168 46.0273 24.9414 44.6992C22.6758 43.3711 20.8789 41.5742 19.5508 39.3086C18.2227 37.082 17.5391 34.5625 17.5 31.75C17.5391 28.9375 18.2227 26.418 19.5508 24.1914C20.8789 21.9258 22.6758 20.1289 24.9414 18.8008C27.168 17.4727 29.6875 16.7891 32.5 16.75C35.3125 16.7891 37.832 17.4727 40.0586 18.8008C42.3242 20.1289 44.1211 21.9258 45.4492 24.1914C46.7773 26.418 47.4609 28.9375 47.5 31.75ZM32.5 19.5625V43.9375C35.9375 43.8594 38.8086 42.668 41.1133 40.3633C43.418 38.0586 44.6094 35.1875 44.6875 31.75C44.6094 28.3125 43.418 25.4414 41.1133 23.1367C38.8086 20.832 35.9375 19.6406 32.5 19.5625Z'
          fill='white'
        />
      </svg>
    )}
    {status === 'UPCOMING' && (
      <svg
        width='50'
        height='50'
        viewBox='0 0 65 64'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          x='0.629395'
          y='0.5'
          width='63.4364'
          height='63'
          rx='31.5'
          fill='#F7F7F7'
          stroke='#DBDBDB'
          strokeDasharray='2 2'
        />
      </svg>
    )}
  </>
)

const Step: FC<StepType> = ({
  children,
  currentValue,
  subtitle,
  title,
  value,
}) => {
  const status = useMemo(() => {
    if (value === currentValue) {
      return 'IN_PROGRESS'
    }
    if (value > currentValue) {
      return 'UPCOMING'
    }
    return 'COMPLETED'
  }, [value, currentValue])
  return (
    <div className='flex-1 h-100 d-flex flex-column justify-content-center'>
      <Card
        className='w-100 h-100 d-flex align-items-center'
        style={status === 'UPCOMING' ? { opacity: 0.5 } : undefined}
      >
        <div className='flex-row flex-1 flex-nowrap'>
          <div style={{ width: 50 }}>
            <StepIcon status={status} />
          </div>
          <div className='ms-4'>
            <h4 className='mb-1'>{title}</h4>
            <span className='text-muted'>{subtitle}</span>
          </div>
        </div>
        {children}
      </Card>
    </div>
  )
}

export default Step
