import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'

type InitialStateType = Res['Devices'] | null

const initialState = null as InitialStateType

export const DevicesSlice = createSlice({
  initialState,
  name: 'Devices',
  reducers: {
    setDevices(state, action: PayloadAction<Req['setDevices']>) {
      state = action.payload
      return state
    },
  },
})

export const DevicesActions = DevicesSlice.actions
export const useDevicesActions = () => {
  const dispatch = useDispatch()
  const setDevices = useCallback(
    (payload: Req['setDevices']) => {
      return dispatch(DevicesActions.setDevices(payload))
    },
    [dispatch],
  )
  return { setDevices }
}

const selectDevices = (state: StoreStateType) => state.Devices

export const useDevices = () => {
  const { setDevices } = useDevicesActions()
  const Devices = useSelector(selectDevices)
  return useMemo(() => ({ Devices, setDevices }), [setDevices, Devices])
}
