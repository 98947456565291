import Button from './Button'
import { FC, ReactNode, useState } from 'react'
import { useRouter } from 'next/router'
import { fromParam, toParam } from 'common/utils/urls/param'

interface TabsProps {
  value?: number
  onChange?: (index: number) => void
  children?: ReactNode[]
  className?: string
  theme?: 'default' | 'pill'
  urlParam?: string
  uncontrolled?: boolean
  tabLabels: ReactNode[]
  hideNavOnSingleTab?: boolean
  tabLabelStrings?: string[]
  actions?: ReactNode
}

export const Tabs: FC<TabsProps> = ({
  actions,
  children: _children,
  className = '',
  hideNavOnSingleTab,
  onChange,
  tabLabelStrings,
  tabLabels,
  theme = 'default',
  uncontrolled,
  urlParam,
  value: controlledValue = 0,
}) => {
  const children = _children?.length ? _children.filter((v) => !!v) : _children
  const hideNav =
    (!children?.length || children?.length === 1) && hideNavOnSingleTab

  const [_value, setValue] = useState<number>(controlledValue)
  const router = useRouter()
  let value = uncontrolled ? _value : controlledValue
  if (urlParam) {
    const tabParam = fromParam()[urlParam]
    if (tabParam) {
      const tab = (tabLabelStrings || tabLabels).findIndex((v) => {
        return v?.toLowerCase().replace(/ /g, '-') === tabParam
      })
      if (tab !== -1) {
        value = tab
      }
    }
  }
  return hideNav ? (
    <div className={`${className}`}>{children}</div>
  ) : (
    <div className={`tabs ${className}`}>
      <div className={`tabs-nav ${theme}`}>
        {children?.map((child, index) => {
          const isSelected = (uncontrolled ? value : controlledValue) === index

          if (!child) {
            return null
          }

          return (
            <Button
              type='button'
              data-test={
                // @ts-ignore
                child?.props?.['data-test']
              }
              id={
                // @ts-ignore
                child?.props?.id
              }
              key={`button${index}`}
              onClick={(e) => {
                e?.stopPropagation()
                e?.preventDefault()
                if (urlParam) {
                  const currentParams = fromParam()
                  router.replace(
                    `${document.location.pathname}?${toParam({
                      ...currentParams,
                      [urlParam]: (tabLabelStrings || tabLabels)[index]
                        .toLowerCase()
                        .replace(/ /g, '-'),
                    })}`,
                  )
                }
                if (uncontrolled) {
                  setValue(index)
                }
                onChange?.(index)
              }}
              className={`btn-tab ${isSelected ? ' tab-active' : ''}`}
            >
              {tabLabels[index]}
            </Button>
          )
        })}
        {actions}
      </div>
      {children?.[value]}
    </div>
  )
}

Tabs.displayName = 'Tabs'
