import { FC } from 'react'
import { useRouter } from 'next/router'
import Highlight from './Highlight'

type SearchHighlightType = {
  children: string
}

const SearchHighlight: FC<SearchHighlightType> = ({ children }) => {
  const router = useRouter()
  const q = router.query.q
  if (!q) {
    return <>{children}</>
  }
  return <Highlight query={`${q}`}>{children}</Highlight>
}

export default SearchHighlight
