import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import { AuthOptions } from '@aws-amplify/auth/lib-esm/types'

async function oauth(provider: CognitoHostedUIIdentityProvider) {
  await Auth.federatedSignIn({
    provider,
  })
}
const Cognito = {
  appleOauth: async function () {
    return oauth(CognitoHostedUIIdentityProvider.Apple)
  },
  changePassword: async function (
    username: string,
    code: string,
    password: string,
  ) {
    return await Auth.forgotPasswordSubmit(username, code, password)
  },
  completeNewPassword: async function (newPassword: string) {
    return await Auth.completeNewPassword(this.user, newPassword)
  },
  confirmSignIn: async function (code: string) {
    return await Auth.confirmSignIn(this.user, code, 'SOFTWARE_TOKEN_MFA')
  },
  confirmSignUp: async function (username: string, code: string) {
    return await Auth.confirmSignUp(username, code)
  },

  currentAuthenticatedUser: async function (): Promise<CognitoUser> {
    return await Auth.currentAuthenticatedUser()
  },
  facebookOauth: async function () {
    return oauth(CognitoHostedUIIdentityProvider.Facebook)
  },
  getSession: async function () {
    await Auth.currentAuthenticatedUser()
    return await Auth.currentSession()
  },
  googleOauth: async function () {
    return oauth(CognitoHostedUIIdentityProvider.Google)
  },
  init: (config: AuthOptions) => {
    Auth.configure({
      Auth: config,
    })
  },
  login: async function (
    username: string,
    password: string,
  ): Promise<CognitoUserSession> {
    this.user = await Auth.signIn({
      password,
      username,
    })
    return await this.getSession()
  },
  logout: async function () {
    return await Auth.signOut()
  },
  resendConfimationCode: async function (email: string) {
    return await Auth.forgotPassword(email)
  },
  resendConfirmSignUp: async function (username: string) {
    return await Auth.resendSignUp(username)
  },
  resetPassword: async function (email: string) {
    return await Auth.forgotPassword(email)
  },
  setupMfa: async function () {
    const user = await this.currentAuthenticatedUser()

    return await Auth.setupTOTP(user)
  },
  signUp: async function (
    username: string,
    password: string,
    attributes?: Record<any, any>,
  ) {
    try {
      const { user, userConfirmed } = await Auth.signUp({
        attributes,
        password,
        username,
      })
      return {
        user,
        userConfirmed,
      }
    } catch (error) {
      console.log('error signing up:', error)
      throw error
    }
  },
  user: null,
  verifyMfa: async function (authCode: string) {
    const user = await this.currentAuthenticatedUser()
    const res = await Auth.verifyTotpToken(user, authCode)
    await Auth.setPreferredMFA(user, 'TOTP')
    return res
  },
}

export default Cognito
