import { FC, useEffect, useRef } from 'react'
import { QrReader } from 'react-qr-reader'
import { GUID } from 'common/utils/string/GUID'
import useThrottle from 'common/hooks/useThrottle' // we need this to make JSX compile

type QRReaderType = {
  className: string
  onResult: (res: string) => void
}

const QRReader: FC<QRReaderType> = ({ className, onResult }) => {
  const barcodeRef = useRef()
  const uidRef = useRef(GUID())
  const throttledRes = useThrottle(onResult, 2000)
  useEffect(() => {
    const interval = setInterval(() => {
      //@ts-ignore
      if (typeof BarcodeDetector !== 'undefined') {
        //@ts-ignore
        barcodeRef.current = new BarcodeDetector({
          formats: [
            'aztec',
            'code_128',
            'code_39',
            'code_93',
            'data_matrix',
            'ean_13',
            'ean_8',
            'itf',
            'pdf417',
            'qr_code',
            'upc_e',
          ],
        })
        //@ts-ignore
        barcodeRef.current
          .detect(document.getElementById(uidRef.current))
          //@ts-ignore
          .then((barcodes) => {
            //@ts-ignore
            barcodes.forEach((barcode) => {
              if (barcode.rawValue) {
                console.log(barcode.rawValue)
                throttledRes(barcode.rawValue)
              }
            })
          })
          //@ts-ignore
          .catch((err) => {
            console.log(err)
          })
      }
    }, 200)
    return () => {
      clearInterval(interval)
    }
    //eslint-disable-next-line
  }, [])
  return (
    <QrReader
      videoId={uidRef.current}
      constraints={{}}
      className={className}
      onResult={(res) => {
        if (res?.getText()) {
          onResult(res?.getText())
        }
      }}
    />
  )
}

export default QRReader
