import { FC } from 'react'
import Badge from './base/Badge' // we need this to make JSX compile

type CompanyStatusType = {
  status: string | undefined | null
}

const CompanyStatus: FC<CompanyStatusType> = ({ status }) => {
  if (!status) {
    return null
  }
  return (
    <Badge
      theme={
        status === 'ACTIVE'
          ? 'success'
          : status === 'NO_ID'
          ? 'warning'
          : 'danger'
      }
    >
      {status}
    </Badge>
  )
}

export default CompanyStatus
