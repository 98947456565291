import React, {
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Input, { InputType } from './base/forms/Input'
import Dropdown from './dropdown/Dropdown'
import DropdownItem from './dropdown/DropdownItem'
import { safeParseEventValue } from 'common/utils/forms/safeEventParseValue'
import { snakeCase } from 'lodash'
import { GUID } from 'common/utils/string/GUID'
import useSearchThrottle from 'common/hooks/useSearchThrottle'
import classNames from 'classnames'
import { useRouter } from 'next/router'

type AutocompleteType = Omit<InputType, 'onChange' | 'onSelect'> & {
  items: string[] | undefined
  renderItem?: (data: {
    item: string
    index: number
    onClick: () => void
  }) => ReactNode
  search?: string
  clearOnSelect?: boolean
  useClientsideFilter?: boolean
  onChange: (value: string) => void
  onSelect: (value: string, index: number) => void
  autoCompleteClassName?: string
  onEnterPress?: (value: string) => void
}

const Autocomplete: FC<AutocompleteType> = ({
  autoCompleteClassName,
  clearOnSelect,
  items = [],
  onChange,
  onEnterPress,
  onSelect,
  renderItem = ({ index, item, onClick }) => (
    <DropdownItem onClick={onClick}>{item}</DropdownItem>
  ),
  search,
  useClientsideFilter,
  value,
  ...rest
}) => {
  const [show, setShow] = useState(false)
  const ref = useRef(GUID())

  const { searchInput, setSearchInput } = useSearchThrottle(
    value === null || value === undefined ? '' : `${value}`,
    onChange,
  )
  const router = useRouter()

  // Close dropdown on route change
  useEffect(() => {
    const handleRouteChange = () => setShow(false)
    router.events.on('routeChangeStart', handleRouteChange)

    // Cleanup the event listener on component unmount
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])
  useEffect(() => {
    if (search) {
      setSearchInput(search)
    }
  }, [search])
  useEffect(() => {
    if (value) {
      setSearchInput(clearOnSelect ? '' : `${value}`)
    }
  }, [setSearchInput, clearOnSelect, value])

  const inner = (
    <Input
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          setShow(false)
          onEnterPress?.(searchInput)
        }
      }}
      key={ref.current}
      value={searchInput}
      onChange={(e) => setSearchInput(safeParseEventValue(e))}
      onFocus={() => setShow(true)}
      {...rest}
      spellCheck={false}
      autoComplete='one-time-code'
    />
  )

  const filteredItems = useClientsideFilter
    ? useMemo(() => {
        const lower = snakeCase(searchInput)
        return searchInput
          ? items.filter((v) => snakeCase(v).includes(lower))
          : items
      }, [items, searchInput])
    : items

  return (
    <Dropdown
      hidden={!filteredItems.length}
      show={show}
      setShow={setShow}
      dropdownClassName={classNames('px-0 autocomplete', autoCompleteClassName)}
      dropdownContent={filteredItems.map((item, index) =>
        renderItem({
          index,
          item,
          onClick: () => {
            onChange(item)
            onSelect(item, index)
            setShow(false)
          },
        }),
      )}
    >
      {inner}
    </Dropdown>
  )
}

export default Autocomplete
