import React, { FC, useEffect, useState } from 'react'
import Autocomplete from './AutoComplete'
import { useGetSubscriptionsQuery } from 'common/services/useSubscription'
import DropdownItem from './dropdown/DropdownItem'
import { Plan, SubscriptionSummary } from 'common/types/responses'
import CompanyName from './CompanyName'
import Badge from './base/Badge'
import { getAddressPartsForSubscription } from '../pages/mail/forwards'
import Tooltip from './base/Tooltip'
import Highlight from './Highlight'
import { useGetCompaniesQuery } from 'common/services/useCompany'

type CustomerSearchType = {
  id?: string
  value?: SubscriptionSummary | null
  autoFocus?: boolean
  search?: string
  onChange: (v: SubscriptionSummary | null) => void
  plans?: Plan[]
  placeholder?: string
  getSearchQuery?: (v: string) => void
  onEnterPress?: (value: string) => void
}

const CustomerSearch: FC<CustomerSearchType> = ({
  autoFocus,
  getSearchQuery,
  id,
  onChange,
  onEnterPress,
  placeholder,
  plans = [
    'Collect',
    'Scan',
    'Scan and Forward',
    'International Forward',
    'Forward',
    'HMRC Forward',
    'HMRC Scan',
  ],
  search,
  value,
}) => {
  const [q, setQ] = useState('')
  const { data } = useGetCompaniesQuery(
    {
      page_size: 3,
      plan_types: plans?.join(','),
      q,
    },
    {
      skip: !q,
    },
  )
  useEffect(() => {
    if (search) {
      setQ(search)
      document.getElementById('customer-name')?.focus()
    }
  }, [search])

  return (
    <>
      <Autocomplete
        onEnterPress={onEnterPress}
        search={search}
        autoFocus={autoFocus}
        id={id || 'customer-name'}
        value={value?.name}
        isValid={!!value}
        clearOnSelect
        placeholder={placeholder || 'Start typing the company name...'}
        onSelect={(v, index) => onChange(data!.results[index]!)}
        renderItem={({ index, onClick }) => {
          const company = data?.results?.[index]
          if (!company) return null
          return (
            <DropdownItem onClick={onClick} className='border-bottom'>
              <div className='flex-row py-1 text-wrap flex-nowrap'>
                <div className='flex-fill lh-1'>
                  <div className='body-secondary mb-1'>
                    <CompanyName
                      companySuggestion={q}
                      contactSuggestion={q}
                      company={company}
                    />
                  </div>
                  {company.forwarding_address &&
                    getAddressPartsForSubscription(company).join(', ')}
                  <div className='d-flex'>
                    <Tooltip
                      content={
                        company?.directors.length ? (
                          <Highlight query={q}>
                            {company.directors.map((v) => v.name).join(', ')}
                          </Highlight>
                        ) : null
                      }
                    >
                      <span className='text-muted d-flex align-items-center align-self-start mt-1'>
                        {company.contact_name}
                        {!!company.directors?.length && (
                          <Badge theme='primary'>
                            {company.directors?.length &&
                              `+${company.directors?.length} `}
                          </Badge>
                        )}
                      </span>
                    </Tooltip>
                  </div>
                </div>
                {company.plan}
              </div>
            </DropdownItem>
          )
        }}
        items={data?.results.map((v) => v.id)}
        onChange={(v) => {
          setQ(v)
          getSearchQuery?.(v)
        }}
      />
      {!!value && (
        <Badge
          onClick={() => onChange(null)}
          className='flex-row border-1 cursor-pointer mt-4 py-2 text-wrap flex-nowrap'
        >
          <div className='flex-fill body-secondary'>
            <div className='fw-semibold'>{value.name}</div>
            <div>{value.contact_name}</div>
            <div>({value.plan})</div>
          </div>
          <span className={'fa fa-times ms-2'} />
        </Badge>
      )}
    </>
  )
}

export default CustomerSearch
