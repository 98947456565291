// @ts-ignore
const DEV = global.__DEV__
  ? // @ts-ignore
    global.__DEV__
  : typeof __DEV__ === 'undefined'
  ? false
  : __DEV__

export const Constants = {
  E2E: DEV && false,
  E2E_NAMESPACE: null,
  REQUEST_TIMEOUT: 10000,
  defaultLocale: 'en',
  enableSentry: false,
  lockDuration: 120,
  projectName: 'The Project',
  simulate: !DEV
    ? {}
    : {
        CONFIRM_EMAIL: false,
        FORCE_LANGUAGE: '',
        FORCE_ONBOARDING: false,
        FORCE_PAGE: null,
        FORCE_SUB_PAGE: null,
        SKIP_PAYWALL: false,
      },
}
