import { FC, useCallback, useEffect, useState } from 'react'
import Button from './base/forms/Button'
import Select from './base/forms/Select'
import { useDevices } from 'common/services/useDevices'
import { useActiveDevice } from 'common/services/useActiveDevice'
import useActiveDeviceDetail from 'common/hooks/useActiveDeviceDetail'

type DeviceSearcherType = {}

const DeviceSearcher: FC<DeviceSearcherType> = ({}) => {
  const { activeDevice, setActiveDevice } = useActiveDevice()
  const { Devices, setDevices } = useDevices()
  const { device } = useActiveDeviceDetail()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const getDevices = useCallback(
    (force?: boolean) => {
      if (!Devices?.length || force) {
        setError(false)
        setLoading(true)
        BrowserPrint.getLocalDevices(
          (res) => {
            const devices = res?.printer
            setLoading(false)
            setDevices(devices || [])
            if (
              typeof activeDevice !== 'number' ||
              (!devices?.[activeDevice] && !!devices?.length)
            ) {
              setActiveDevice(0)
            }
          },
          () => {
            setLoading(false)
            setError(true)
          },
        )
      }
    },
    [activeDevice, Devices, setActiveDevice, setDevices],
  )

  useEffect(() => {
    getDevices()
  }, [getDevices])

  return (
    <>
      <div style={{ width: 300 }}>
        <div className='flex-row'>
          <Select
            options={Devices?.map((d, i) => ({ label: d.name, value: i }))}
            value={
              device
                ? {
                    label: device.name,
                    value: Devices?.findIndex((v) => v.name === device.name),
                  }
                : null
            }
            onChange={(v) => setActiveDevice(v.value)}
            isValid={!error}
            shouldValidate
            placeholder={error ? 'No devices found' : 'Select a device'}
          />
          <Button
            theme='secondary'
            onClick={() => getDevices(true)}
            className='ms-2'
          >
            Refresh
          </Button>
        </div>
      </div>
    </>
  )
}

export default DeviceSearcher
