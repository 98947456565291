import { CompanyDetail, Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { toParam } from 'common/utils/urls/param'
import { BusinessType } from 'components/BusinessTypeSelect'
import transformPagination from 'common/utils/transformPagination'

export const companyService = service
  .enhanceEndpoints({ addTagTypes: ['Company'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCompanies: builder.query<Res['companies'], Req['getCompanies']>({
        providesTags: [{ id: 'LIST', type: 'Company' }],
        query: (query) => ({
          url: `companies/?${toParam(query)}`,
        }),
        transformResponse: (res, _, req) => {
          return transformPagination(req, res)
        },
      }),
      getCompany: builder.query<Res['company'], Req['getCompany']>({
        providesTags: (res) => [{ id: res?.id, type: 'Company' }],
        query: (query: Req['getCompany']) => ({
          url: `companies/${query.id}/subscriptions/${query.subscriptionId}`,
        }),
        transformResponse: (v: CompanyDetail) => ({
          ...v,
          subscriptions: v.subscriptions?.map((v) => ({
            ...v,
            organisation_type: convertOrganisationType(
              v.organisation_type as any,
            ),
          })),
        }),
      }),
      updateCompany: builder.mutation<Res['company'], Req['updateCompany']>({
        invalidatesTags: (res, _, query) => [
          { id: query.company_id, type: 'Company' },
        ],
        query: (query) => {
          const { mail_notes, notes } = query
          return {
            body: { mail_notes, notes },
            method: 'PUT',
            url: `companies/${query.company_id}/subscriptions/${query.subscription_id}/update`,
          }
        },
      }),
      updateCompanyEmail: builder.mutation<
        Res['updateCompanyEmail'],
        Req['updateCompanyEmail']
      >({
        invalidatesTags: (res, _, query) => [
          { id: query.company_id, type: 'Company' },
        ],
        query: (query) => ({
          body: query,
          method: 'POST',
          url: `companies/${query.company_id}/update-email`,
        }),
      }),
      // END OF ENDPOINTS
    }),
  })

function convertOrganisationType(v: number | undefined) {
  switch (v) {
    case 1:
      return BusinessType.LIMITED_COMPANY
    case 3:
      return BusinessType.SOLE_TRADER
    case 9:
      return BusinessType.UNINCORPORATED
    case 10:
      return BusinessType.NON_PROFIT
    case 12:
      return BusinessType.TRUST
    case 13:
      return BusinessType.ASSOCIATION
    default:
      return ''
  }
}

export async function getCompanies(
  store: any,
  data: Req['getCompanies'],
  options?: Parameters<
    typeof companyService.endpoints.getCompanies.initiate
  >[1],
) {
  return store.dispatch(
    companyService.endpoints.getCompanies.initiate(data, options),
  )
}
export async function getCompany(
  store: any,
  data: Req['getCompany'],
  options?: Parameters<typeof companyService.endpoints.getCompany.initiate>[1],
) {
  return store.dispatch(
    companyService.endpoints.getCompany.initiate(data, options),
  )
}
export async function updateCompany(
  store: any,
  data: Req['updateCompany'],
  options?: Parameters<
    typeof companyService.endpoints.updateCompany.initiate
  >[1],
) {
  return store.dispatch(
    companyService.endpoints.updateCompany.initiate(data, options),
  )
}
export async function updateCompanyEmail(
  store: any,
  data: Req['updateCompanyEmail'],
  options?: Parameters<
    typeof companyService.endpoints.updateCompanyEmail.initiate
  >[1],
) {
  return store.dispatch(
    companyService.endpoints.updateCompanyEmail.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useUpdateCompanyEmailMutation,
  useUpdateCompanyMutation,
  // END OF EXPORTS
} = companyService

/* Usage examples:
const { data, isLoading } = useGetCompaniesQuery({ id: 2 }, {}) //get hook
const [createCompanies, { isLoading, data, isSuccess }] = useCreateCompaniesMutation() //create hook
companyService.endpoints.getCompanies.select({id: 2})(store.getState()) //access data from any function
*/
