import React, { FC, ReactNode } from 'react'
import classNames from 'classnames'
import Tooltip from 'components/base/Tooltip'
import Input, { InputType } from './Input' // Ensure you import the correct path for Input

export interface InputGroupProps extends InputType {
  action?: ReactNode
  className?: string
  component?: ReactNode
  defaultValue?: string
  errorMessage?: string
  id?: string
  inputProps?: InputType
  isValid?: boolean
  onBlur?: InputType['onBlur']
  placeholder?: string
  required?: boolean
  textarea?: boolean
  title?: string
  copy?: boolean
  tooltip?: string
}

const InputGroup: FC<InputGroupProps> = ({
  action,
  className,
  component,
  defaultValue,
  disabled,
  errorMessage,
  id: providedId,
  inputProps = {},
  isValid,
  name,
  onBlur,
  onChange,
  placeholder,
  required,
  textarea,
  title,
  tooltip,
  type = 'text',
  value,
}) => {
  const id = providedId || name || inputProps.id || inputProps.name

  return (
    <div className={classNames('form-group', className)}>
      {(title || action) && (
        <div className='flex-row align-items-center'>
          <Tooltip place='top' content={tooltip}>
            <label
              className='form-label fw-semibold body-secondary flex-fill'
              htmlFor={id}
            >
              {tooltip && <i className='fa fa-info-circle me-2' />}
              {title}
              {required && <span className='text-danger'>*</span>}
            </label>
            {action}
          </Tooltip>
        </div>
      )}

      {errorMessage && (
        <span
          id={inputProps.name ? `${inputProps.name}-error` : ''}
          className='text-danger'
        >
          {errorMessage}
        </span>
      )}

      {component ? (
        component
      ) : (
        <Input
          name={name}
          textarea={textarea}
          onBlur={onBlur}
          {...inputProps}
          isValid={isValid}
          disabled={disabled}
          value={value}
          data-test={['data-test']}
          defaultValue={defaultValue}
          onChange={onChange}
          type={type || 'text'}
          id={id}
          placeholder={placeholder}
        />
      )}
    </div>
  )
}

InputGroup.displayName = 'InputGroup'
export default InputGroup
