import { EnvelopeType, MailItem, MailStatus, Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { responses } from 'common/types/mock-data'
import { enumeration } from 'common/utils/string/enumeration'
import dayjs from 'dayjs'
import { toParam } from 'common/utils/urls/param'
import transformPagination from 'common/utils/transformPagination'
import { formatMailLocation } from 'common/utils/string/formatMailLocation'

export const mailItemService = service
  .enhanceEndpoints({ addTagTypes: ['MailItem'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMailItem: builder.query<Res['mailItem'], Req['getMailItem']>({
        providesTags: (res) => [{ id: res?.id, type: 'MailItem' }],
        query: (query: Req['getMailItem']) => ({
          url: `mails/${query.id}`,
        }),
        transformResponse: (res) => {
          const data = {
            ...res,
            location: formatMailLocation(res.location),
          }
          return data
        },
      }),
      getMailItems: builder.query<Res['mailItems'], Req['getMailItems']>({
        providesTags: [{ id: 'LIST', type: 'MailItem' }],
        query: (q) => ({
          url: `mails/?${toParam(q)}`,
        }),
        transformResponse: (res, _, req) => {
          const response = {
            ...res,
            results: res.results.map((item: MailItem) => ({
              ...item,
              location: formatMailLocation(item.location || ''),
            })),
          }
          return transformPagination(req, response)
        },
      }),
      updateMailItem: builder.mutation<Res['mailItem'], Req['updateMailItem']>({
        invalidatesTags: (res) => [
          { id: 'LIST', type: 'MailItem' },
          { id: res?.id, type: 'MailItem' },
        ],
        query: (query: Req['updateMailItem']) => ({
          body: query,
          method: 'PATCH',
          url: `mails/${query.id}`,
        }),
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getMailItems(
  store: any,
  data: Req['getMailItems'],
  options?: Parameters<
    typeof mailItemService.endpoints.getMailItems.initiate
  >[1],
) {
  return store.dispatch(
    mailItemService.endpoints.getMailItems.initiate(data, options),
  )
}
export async function getMailItem(
  store: any,
  data: Req['getMailItem'],
  options?: Parameters<
    typeof mailItemService.endpoints.getMailItem.initiate
  >[1],
) {
  return store.dispatch(
    mailItemService.endpoints.getMailItem.initiate(data, options),
  )
}
export async function updateMailItem(
  store: any,
  data: Req['updateMailItem'],
  options?: Parameters<
    typeof mailItemService.endpoints.updateMailItem.initiate
  >[1],
) {
  return store.dispatch(
    mailItemService.endpoints.updateMailItem.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useGetMailItemQuery,
  useGetMailItemsQuery,
  useUpdateMailItemMutation,
  // END OF EXPORTS
} = mailItemService

/* Usage examples:
const { data, isLoading } = useGetMailItemsQuery({ id: 2 }, {}) //get hook
const [createMailItems, { isLoading, data, isSuccess }] = useCreateMailItemsMutation() //create hook
mailItemService.endpoints.getMailItems.select({id: 2})(store.getState()) //access data from any function
*/

export function parseMailStatus(
  status: MailStatus | undefined,
  expiry: string,
  address: string | null,
) {
  const prettyStatus = status?.split(':')?.[0]?.replace('.', '')
  let actualStatus: MailStatus | undefined = status
  switch (prettyStatus) {
    case 'Mail Ready': {
      actualStatus = 'CREATED'
      break
    }
    case 'Forwarding Requested': {
      actualStatus = 'REQUESTED_FORWARD'
      break
    }
    case 'Scan and Forward Complete': {
      actualStatus = 'FORWARDED'
      break
    }
    case 'Forwarding Complete': {
      actualStatus = 'FORWARDED'
      break
    }
    case 'Post Scanned':
    case 'Scan Complete': {
      actualStatus = 'SCANNED'
      break
    }
    case 'Mail collected': {
      actualStatus = 'COLLECTED'
      break
    }
    default: {
      actualStatus = status
      break
    }
  }

  switch (actualStatus) {
    case 'ACCOUNT_FROZEN':
      return 'Your account has been frozen, please contact us to regain access.'
    case 'COLLECTED':
      return 'Your mail has been collected.'
    case 'CREATED': {
      return `Mail items will be stored at no cost for 14 days. If you require a
              physical copy, please collect or request a Forward before ${dayjs(
                expiry,
              ).format('DD MMMM YYYY')}`
    }
    case 'DESTROYED':
      return 'As per the storage policy in our Terms and Conditions, this mail item was destroyed after 14 days'
    case 'FORWARDED': {
      const formattedAddress =
        address
          ?.split(/[\n\r]/g)
          .filter((v) => !!v)
          .map((v) => v.replace(',', ''))
          .join(', ') || ''
      return `We have forwarded this mail item to ${formattedAddress}.`
    }
    case 'ON_HOLD_INVOICE':
      return 'Your mail is being held whilst you update your payment method.'
    case 'ON_HOLD_KYC_ID':
      return 'Your mail is being held whilst you verify your identity.'
    case 'PENDING':
    case 'PROCESSING':
      return 'Your mail is being processed.'
    case 'REFUSED':
      return 'This mail item has been refused by our agents.'
    case 'REJECTED':
      return 'This mail item has been rejected by our agents.'
    case 'REQUESTED_FORWARD':
    case 'REQUESTED_SCAN':
      return 'We are processing your request.'
    case 'RETURNED':
      return 'This mail item is not covered in your plan and has been returned to the sender.'
    case 'SCANNED':
      return 'Your mail is ready for download.'
    default:
      return `${enumeration.get(prettyStatus)}`
  }
}

export function formatStatus(status: MailStatus | string | undefined) {
  switch (status) {
    case 'CREATED': {
      return `Ready`
    }
    case 'ON_HOLD_INVOICE':
      return 'Awaiting payment'
    case 'ON_HOLD_KYC_ID':
      return 'Awaiting identity verification'
    default:
      return `${enumeration.get(status?.split?.(':')?.[0])}`
  }
}

export const requiresDimensions = (envelopeType: EnvelopeType) =>
  envelopeType === 'PARCEL'
