import cn from 'classnames'
import { FC, ReactNode } from 'react'
import { parseError } from 'common/utils/parseError'

interface Messages {
  /** The error message to be displayed, replaces \n */
  className?: string
  icon?: string | ReactNode
  'data-test'?: string
}

//Generic error message
const Message: FC<Messages> = ({
  children,
  className,
  'data-test': dataTest,
  icon,
}) => {
  if (!children) {
    return null
  }

  return (
    <>
      <div
        data-test={dataTest}
        className={`p-4 fw-semibold rounded border border-1 bg-opacity-10 ${
          className || ''
        }`}
      >
        <div className='flex-row align-items-center'>
          {icon && typeof icon === 'string' ? (
            <span className={cn({ icon: true }, 'me-3', icon)} />
          ) : (
            icon
          )}
          <div style={{ flex: 1 }} data-test='message flex-1'>
            {typeof children === 'string'
              ? children.replace(/\n/g, '')
              : children}
          </div>
        </div>
      </div>
    </>
  )
}

//Default message added alert-danger
export const ErrorMessage: FC<Messages> = ({ className, ...props }) =>
  !props.children ? null : (
    <Message
      {...props}
      icon={
        props.icon !== undefined ? (
          props.icon
        ) : (
          <svg
            className='me-2'
            width='28'
            height='28'
            viewBox='0 0 28 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M17.1582 15.5084C17.6144 15.9646 17.6144 16.7019 17.1582 17.1581C16.9307 17.3856 16.632 17.4999 16.3334 17.4999C16.0347 17.4999 15.736 17.3856 15.5085 17.1581L14 15.6496L12.4915 17.1581C12.264 17.3856 11.9654 17.4999 11.6667 17.4999C11.368 17.4999 11.0694 17.3856 10.8419 17.1581C10.3857 16.7019 10.3857 15.9646 10.8419 15.5084L12.3504 13.9999L10.8419 12.4914C10.3857 12.0352 10.3857 11.2979 10.8419 10.8417C11.298 10.3856 12.0354 10.3856 12.4915 10.8417L14 12.3502L15.5085 10.8417C15.9647 10.3856 16.702 10.3856 17.1582 10.8417C17.6144 11.2979 17.6144 12.0352 17.1582 12.4914L15.6497 13.9999L17.1582 15.5084ZM14 2.33322C7.56704 2.33322 2.33337 7.56689 2.33337 13.9999C2.33337 20.4329 7.56704 25.6666 14 25.6666C20.433 25.6666 25.6667 20.4329 25.6667 13.9999C25.6667 7.56689 20.433 2.33322 14 2.33322Z'
              fill='#EF4D56'
            />
          </svg>
        )
      }
      className={cn(className, 'bg-danger border-danger')}
    >
      {parseError(props.children)}
    </Message>
  )

export const ErrorIcon: FC<{}> = ({}) => {
  return (
    <svg
      className='me-2'
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.1667 15.1667C15.1667 15.8107 14.644 16.3333 14 16.3333C13.356 16.3333 12.8334 15.8107 12.8334 15.1667V10.5C12.8334 9.85601 13.356 9.33334 14 9.33334C14.644 9.33334 15.1667 9.85601 15.1667 10.5V15.1667ZM14 19.8333C13.356 19.8333 12.8334 19.3107 12.8334 18.6667C12.8334 18.0227 13.356 17.5 14 17.5C14.644 17.5 15.1667 18.0227 15.1667 18.6667C15.1667 19.3107 14.644 19.8333 14 19.8333ZM26.3212 19.0202L17.3705 4.18134C16.6717 3.02401 15.4117 2.33334 14 2.33334C12.5884 2.33334 11.3284 3.02401 10.6295 4.18134L1.67886 19.0202C1.01619 20.1203 0.99636 21.4433 1.62636 22.5587C2.30186 23.7568 3.61436 24.5 5.04936 24.5H22.9507C24.3857 24.5 25.6982 23.7568 26.3737 22.5587C27.0037 21.4433 26.9839 20.1203 26.3212 19.0202Z'
        fill='#CD384D'
      />
    </svg>
  )
}
export const InfoIcon: FC<{}> = ({}) => {
  return (
    <svg
      className='me-2'
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 10.5C13.356 10.5 12.8334 9.97734 12.8334 9.33334C12.8334 8.68934 13.356 8.16668 14 8.16668C14.644 8.16668 15.1667 8.68934 15.1667 9.33334C15.1667 9.97734 14.644 10.5 14 10.5ZM15.1667 18.6667C15.1667 19.3107 14.644 19.8333 14 19.8333C13.356 19.8333 12.8334 19.3107 12.8334 18.6667V12.8333C12.8334 12.1893 13.356 11.6667 14 11.6667C14.644 11.6667 15.1667 12.1893 15.1667 12.8333V18.6667ZM14 2.33334C7.55654 2.33334 2.33337 7.55651 2.33337 14C2.33337 20.4435 7.55654 25.6667 14 25.6667C20.4424 25.6667 25.6667 20.4435 25.6667 14C25.6667 7.55651 20.4424 2.33334 14 2.33334Z'
        fill='#449FC4'
      />
    </svg>
  )
}
export const SuccessIcon: FC<{}> = ({}) => {
  return (
    <svg
      className='me-2'
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.0112 11.2064L13.6819 18.2064C13.4626 18.4946 13.1231 18.6649 12.7614 18.6673H12.7532C12.3951 18.6673 12.0567 18.5016 11.8351 18.2193L8.99774 14.5944C8.60107 14.0881 8.68974 13.3543 9.19724 12.9576C9.70357 12.5598 10.4386 12.6484 10.8352 13.1571L12.7404 15.5908L17.1551 9.79359C17.5447 9.28142 18.2762 9.18109 18.7907 9.57192C19.3029 9.96276 19.4021 10.6943 19.0112 11.2064ZM14.0004 2.33392C7.55691 2.33392 2.33374 7.55709 2.33374 14.0006C2.33374 20.4429 7.55691 25.6673 14.0004 25.6673C20.4439 25.6673 25.6671 20.4429 25.6671 14.0006C25.6671 7.55709 20.4439 2.33392 14.0004 2.33392Z'
        fill='#4CB67E'
      />
    </svg>
  )
}
export const WarningIcon: FC<{}> = ({}) => {
  return (
    <svg
      className='me-2'
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.1667 15.1667C15.1667 15.8107 14.644 16.3333 14 16.3333C13.356 16.3333 12.8334 15.8107 12.8334 15.1667V10.5C12.8334 9.85601 13.356 9.33334 14 9.33334C14.644 9.33334 15.1667 9.85601 15.1667 10.5V15.1667ZM14 19.8333C13.356 19.8333 12.8334 19.3107 12.8334 18.6667C12.8334 18.0227 13.356 17.5 14 17.5C14.644 17.5 15.1667 18.0227 15.1667 18.6667C15.1667 19.3107 14.644 19.8333 14 19.8333ZM26.3212 19.0202L17.3705 4.18134C16.6717 3.02401 15.4117 2.33334 14 2.33334C12.5884 2.33334 11.3284 3.02401 10.6295 4.18134L1.67886 19.0202C1.01619 20.1203 0.99636 21.4433 1.62636 22.5587C2.30186 23.7568 3.61436 24.5 5.04936 24.5H22.9507C24.3857 24.5 25.6982 23.7568 26.3737 22.5587C27.0037 21.4433 26.9839 20.1203 26.3212 19.0202Z'
        fill='#E49A43'
      />
    </svg>
  )
}

// Default message added alert-success
export const SuccessMessage: FC<Messages> = ({ className, ...props }) =>
  !props.children ? null : (
    <Message
      {...props}
      icon={props.icon || <SuccessIcon />}
      className={cn(className, 'bg-success border-success')}
    />
  )

export const InfoMessage: FC<Messages> = ({ className, ...props }) =>
  !props.children ? null : (
    <Message
      {...props}
      icon={props.icon || <InfoIcon />}
      className={cn(className, 'bg-info border-info')}
    />
  )

export const WarningMessage: FC<Messages> = ({ className, ...props }) =>
  !props.children ? null : (
    <Message
      {...props}
      icon={props.icon || <WarningIcon />}
      className={cn(className, 'bg-warning border-warning')}
    />
  )

Message.displayName = 'ErrorMessage'
