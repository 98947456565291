import { FC } from 'react'
import classNames from 'classnames'
import ChevronDown from 'components/icons/ChevronDown'

type DropdownIconType = {
  className?: string
}
const SelectIcon: FC<DropdownIconType> = ({ className, ...rest }) => {
  return (
    <div className={classNames('input-icon', className)} {...rest}>
      <ChevronDown />
    </div>
  )
}

export default SelectIcon
