import {
  FC,
  ReactNode,
  useRef,
  useState,
  useEffect,
  KeyboardEvent,
  cloneElement,
} from 'react'
import classNames from 'classnames'
import useOnClickOutside from 'project/hooks/useOnClickOutside'

export type DropdownType = {
  className?: string
  dropdownClassName?: string
  dropdownContent: ReactNode | ReactNode[]
  icon?: ReactNode
  show: boolean
  position?: 'right'
  preventCloseOnClick?: boolean
  hidden?: boolean
  setShow: (show: boolean) => void
}

const Dropdown: FC<DropdownType> = ({
  children,
  className,
  dropdownClassName,
  dropdownContent,
  hidden,
  position,
  preventCloseOnClick,
  setShow,
  show,
}) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null)

  const onClickOutside = () => {
    if (show) {
      setShow(false)
      setHighlightedIndex(null)
    }
  }

  useOnClickOutside(ref, onClickOutside, 'mousedown')

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
      if (Array.isArray(dropdownContent) && dropdownContent.length > 0) {
        if (event.key === 'ArrowDown') {
          setHighlightedIndex((prevIndex) =>
            prevIndex === null || prevIndex === dropdownContent.length - 1
              ? 0
              : prevIndex + 1,
          )
        } else if (event.key === 'ArrowUp') {
          setHighlightedIndex((prevIndex) =>
            prevIndex === null || prevIndex === 0
              ? dropdownContent.length - 1
              : prevIndex - 1,
          )
        } else if (event.key === 'Enter') {
          if (highlightedIndex !== null) {
            const itemOnClick = (dropdownContent as React.ReactElement[])[
              highlightedIndex
            ]?.props?.onClick
            handleItemClick(itemOnClick)
          }
        }
      }
    }

    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [dropdownContent, highlightedIndex])

  // keep scroll
  useEffect(() => {
    if (ref.current && highlightedIndex !== null) {
      const container = ref.current.querySelector('.dropdown-menu')
      const item = ref.current.querySelectorAll('.dropdown-item')[
        highlightedIndex
      ] as HTMLElement
      if (container && item) {
        const containerRect = container.getBoundingClientRect()
        const itemRect = item.getBoundingClientRect()
        if (itemRect.bottom > containerRect.bottom) {
          container.scrollTop =
            item.offsetTop + item.offsetHeight - container.offsetHeight
        } else if (itemRect.top < containerRect.top) {
          container.scrollTop = item.offsetTop
        }
      }
    }
  }, [highlightedIndex])

  const handleItemClick = (itemOnClick: (() => void) | undefined) => {
    if (itemOnClick) itemOnClick()
    setHighlightedIndex(null)
    if (!preventCloseOnClick) {
      setShow(false)
    }
  }

  return (
    <div
      ref={ref}
      className={classNames('dropdown position-relative', className)}
      aria-expanded={show}
    >
      {children}
      {!hidden && (
        <div
          onClick={(e) => {
            e.stopPropagation()
            if (!Array.isArray(dropdownContent) && !preventCloseOnClick) {
              setShow(false)
            }
          }}
          style={{ minWidth: '100%' }}
          className={show ? 'py-1 position-absolute' : ''}
        >
          <div
            className={classNames(
              'dropdown-menu p-0',
              { 'right-0': position === 'right', show },
              dropdownClassName,
            )}
            aria-labelledby='navbarDropdown'
          >
            {Array.isArray(dropdownContent)
              ? dropdownContent.map((item, index) => {
                  const itemOnClick = (item as React.ReactElement)?.props
                    ?.onClick
                  return cloneElement(item as React.ReactElement, {
                    className: classNames(item.props.className, {
                      active: index === highlightedIndex,
                    }),
                    key: index,
                    onClick: () => handleItemClick(itemOnClick),
                  })
                })
              : dropdownContent}
          </div>
        </div>
      )}
    </div>
  )
}

export default Dropdown
