import { FC, useRef, useState } from 'react'
import Button from './base/forms/Button'
import { toast } from './base/Toast'
import useActiveDeviceDetail from 'common/hooks/useActiveDeviceDetail'

type ZPLItemActionsType = {
  value: string
}

const ZPLItemActions: FC<ZPLItemActionsType> = ({ value }) => {
  const [saving, setSaving] = useState(false)
  const { writeData } = useActiveDeviceDetail()

  const sendData = function (message: string) {
    if (writeData) {
      setSaving(true)
      writeData(message)
        .then((res) => {
          toast('Printing action', null, 'success')
        })
        .catch((res) => {
          toast('Error', null, 'danger')
          console.error(res)
        })
        .finally(() => {
          setSaving(false)
        })
    }
  }
  const ref = useRef<HTMLTextAreaElement>()
  const copy = () => {
    if (ref.current?.value) {
      navigator.clipboard.writeText(ref.current.value)
    }
  }
  return (
    <>
      <div className='flex-fill'>
        <textarea ref={ref as any} className='w-100' value={value}></textarea>
      </div>
      <div className='flex-row align-items-center'>
        <Button disabled={!value} onClick={copy}>
          Copy
        </Button>
        <Button
          onClick={() => sendData(value)}
          disabled={!writeData || saving}
          className='ms-2'
        >
          Send to printer
        </Button>
      </div>
    </>
  )
}

export default ZPLItemActions
