import { FC, HTMLProps } from 'react'
import classNames from 'classnames'

type CardProps = HTMLProps<HTMLDivElement> & {
  title?: string
  padding?: 'sm' | 'xs'
}

const Card: FC<CardProps> = ({
  children,
  className,
  padding,
  title,
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={classNames(
        'bg-card outline rounded',
        {
          'px-1 py-1': padding === 'xs',
          'px-2 py-2': padding === 'sm',
          'px-4 py-4': !padding,
        },
        className,
      )}
    >
      {!!title && <h3>{title}</h3>}
      {children}
    </div>
  )
}

export default Card
