import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'

type InitialStateType = Res['activeDevice'] | null

const initialState = 0 as InitialStateType

export const activeDeviceSlice = createSlice({
  initialState,
  name: 'activeDevice',
  reducers: {
    setActiveDevice(state, action: PayloadAction<Req['setActiveDevice']>) {
      state = action.payload
      return state
    },
  },
})

export const activeDeviceActions = activeDeviceSlice.actions
export const useActiveDeviceActions = () => {
  const dispatch = useDispatch()
  const setActiveDevice = useCallback(
    (payload: Req['setActiveDevice']) => {
      return dispatch(activeDeviceActions.setActiveDevice(payload))
    },
    [dispatch],
  )
  return { setActiveDevice }
}

const selectActiveDevice = (state: StoreStateType) => state.activeDevice

export const useActiveDevice = () => {
  const { setActiveDevice } = useActiveDeviceActions()
  const activeDevice = useSelector(selectActiveDevice)
  return useMemo(
    () => ({ activeDevice, setActiveDevice }),
    [setActiveDevice, activeDevice],
  )
}
