import { ModalProps } from 'reactstrap'
import { FC, ReactNode, useCallback, useState } from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import Confirm from './ModalConfirm'
import ModalDefault from './ModalDefault'
import Alert from './ModalAlert'
import { getStore } from 'common/store'
import { Provider } from 'react-redux'

const withModal = (WrappedComponent: FC<any>) => {
  return (props: ModalProps) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isOpen, setIsOpen] = useState(true)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const toggle = useCallback(() => setIsOpen(false), [setIsOpen])
    global.closeModal = toggle

    return (
      <Provider store={getStore()}>
        <WrappedComponent toggle={toggle} {...props} isOpen={isOpen} />
      </Provider>
    )
  }
}

const _Confirm = withModal(Confirm)
const _Alert = withModal(Alert)
const _ModalDefault = withModal(ModalDefault)

export const openAlert = (global.openAlert = (title, children, onDismiss) => {
  document.getElementById('alert') &&
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    unmountComponentAtNode(document.getElementById('alert')!)
  render(
    <_Alert title={title} onDismiss={onDismiss}>
      {children}
    </_Alert>,
    document.getElementById('alert'),
  )
})
export const openConfirm = (
  title: string,
  body: ReactNode,
  onYes: (toggle: () => void) => void,
  onNo?: () => void,
  challenge?: string,
) => {
  document.getElementById('confirm') &&
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    unmountComponentAtNode(document.getElementById('confirm')!)
  render(
    <_Confirm
      isOpen
      onNo={onNo}
      onYes={onYes}
      title={title}
      challenge={challenge}
    >
      {body}
    </_Confirm>,
    document.getElementById('confirm'),
  )
}

export const openModal = (
  title: string,
  body: ReactNode,
  isSideModal?: boolean,
) => {
  document.getElementById('modal') &&
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    unmountComponentAtNode(document.getElementById('modal')!)
  render(
    <_ModalDefault sideModal={isSideModal} isOpen title={title}>
      {body}
    </_ModalDefault>,
    document.getElementById('modal'),
  )
}
