import { FC, useEffect } from 'react'
import { useGetUserQuery } from 'common/services/useUser'
import { useHydrated } from 'common/services/useHydrated'
import { useFlagsmith } from 'flagsmith/react'
import { useToken } from 'common/services/useToken'

type ProfileContainerType = {}

const ProfileContainer: FC<ProfileContainerType> = ({ children }) => {
  const { data, isLoading } = useGetUserQuery({})
  const { token } = useToken()
  const flagsmith = useFlagsmith()
  const hydrated = useHydrated()
  useEffect(() => {
    if (hydrated && flagsmith.identity !== token?.user?.email_address) {
      if (token?.user?.email_address) {
        flagsmith.identify(token?.user?.email_address, token?.user as any)
      } else {
        flagsmith.logout()
      }
    }
  }, [flagsmith, hydrated, token])
  if (!hydrated || (!data && isLoading)) return null
  return <>{typeof children === 'function' ? children(data) : children}</>
}

export default ProfileContainer
