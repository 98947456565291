import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { Promise } from 'es6-promise'
import { mailItemService } from './useMailItem'
import { getStore } from 'common/store'

export const forwardMailItemService = service
  .enhanceEndpoints({ addTagTypes: ['ForwardMailItem'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createForwardMailItems: builder.mutation<
        Res['forwardMailItems'],
        Req['createForwardMailItems']
      >({
        invalidatesTags: [{ id: 'LIST', type: 'ForwardMailItem' }],
        query: (query: Req['createForwardMailItems']) => ({
          body: query,
          method: 'POST',
          url: `mails/${query.id}/actions/forward`,
        }),
        transformResponse: (res, _, req) => {
          getStore().dispatch(
            mailItemService.util.invalidateTags([
              { id: 'LIST', type: 'MailItem' },
              { id: req.id, type: 'MailItem' },
            ]),
          )
          return res
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export async function createForwardMailItems(
  store: any,
  data: Req['createForwardMailItems'],
  options?: Parameters<
    typeof forwardMailItemService.endpoints.createForwardMailItems.initiate
  >[1],
) {
  return store.dispatch(
    forwardMailItemService.endpoints.createForwardMailItems.initiate(
      data,
      options,
    ),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useCreateForwardMailItemsMutation,
  // END OF EXPORTS
} = forwardMailItemService

/* Usage examples:
const { data, isLoading } = useGetForwardMailItemsQuery({ id: 2 }, {}) //get hook
const [createForwardMailItems, { isLoading, data, isSuccess }] = useCreateForwardMailItemsMutation() //create hook
forwardMailItemService.endpoints.getForwardMailItems.select({id: 2})(store.getState()) //access data from any function
*/
