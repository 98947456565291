import dayjs from 'dayjs'

export const barcodeDateFormat = 'YYMMDD'
export default function decodeBarcode(str: string | undefined | null) {
  if (!str) {
    return {}
  }
  try {
    const dateString = `20${str.slice(0, 6)}`
    const batchString = str.slice(6, 8)
    const documentString = str.slice(8, 12)

    const dateOut = dayjs(dateString, `YY${barcodeDateFormat}`).toISOString()
    return {
      batch: parseInt(batchString),
      date: dateOut,
      documentString: parseInt(documentString),
      location: `${batchString}-${documentString}`,
    }
  } catch (e) {
    console.error(`Invalid barcode${str}`)
  }
}
