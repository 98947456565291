import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { getStore, StoreStateType } from 'common/store'

type InitialStateType = Res['token'] | null

const initialState = null as InitialStateType

export const tokenSlice = createSlice({
  initialState,
  name: 'token',
  reducers: {
    setToken(state, action: PayloadAction<Req['setToken']>) {
      state = action.payload
      return state
    },
  },
})

export const tokenActions = tokenSlice.actions
export const useTokenActions = () => {
  const dispatch = useDispatch()
  const setToken = useCallback(
    (payload: Req['setToken']) => {
      return dispatch(tokenActions.setToken(payload))
    },
    [dispatch],
  )
  return { setToken }
}

const selectToken = (state: StoreStateType) => state.token

export const useToken = () => {
  const { setToken } = useTokenActions()
  const token = useSelector(selectToken)
  return useMemo(() => ({ setToken, token }), [setToken, token])
}
export function setToken(data: Req['setToken']) {
  return getStore().dispatch(tokenActions.setToken(data))
}
